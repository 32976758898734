/* General Styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  font-family: 'Zen Kaku Gothic New', sans-serif;
  background-color: #fff;
  color: #212529;
  line-height: 1.5;
  margin: 0;
  overflow-x: hidden;
}

.centered-text {
  text-align: center;
  margin-bottom: 30px;
  font-size: 14px;
}
/* Container Styles */
.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
}

/* Header */
header {
  background-color: #ffffff;
  padding: 15px 0;
}
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.navbar-brand img {
  max-width: 150px;
  height: auto;
  margin-left: 15px;
}

/* Full Navbar (Visible on Large Screens) */
.navbar-nav {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}
.nav-item {
  margin-left: 20px;
}
.nav-link {
  font-size: 16px;
  font-weight: 600;
  color: #4E4D4E;
  text-decoration: none !important;
  padding: 5px 10px;
}
.nav-link:hover {
  color: #1BAEA4;
}
.bottom-login-member-name {
  position: absolute;
  white-space: nowrap;
  top: 32px;
  right: 3px;
  font-size: 13px;
  margin: 0;
  padding: 0;
  margin-top:20px;
}

/* Hamburger Menu (Visible on Small Screens) */
.hamburger-menu {
  display: none; /* Hidden by default */
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  /* Show the hamburger menu only on small screens */
  .hamburger-menu {
    display: block;
  }

  /* Hide the full navbar on small screens */
  .navbar-nav {
    display: none;
  }

  /* Mobile adjustments for the navbar brand */
  .navbar-brand img {
    max-width: 100px !important;
  }

  .bottom-login-member-name {
    font-size: 12px;
    top: 25px;
    right: -20px;
  }
}

/* Login Form */
.login-form {
  background-color: #fff;
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  margin-bottom: 200px !important;
  max-width: 470px;
  margin: auto;
  top: 400px; /* Adjust the value as needed */
}
.login-form h3 {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
}
.login-form input {
  width: 100%;
  height: 40px;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 3px;
  border: 1px solid #ccc;
  font-size: 14px;
}
.login-form .btn-green {
  font-size: 16px;
  font-weight: 600;
  background-color: #1BAEA4;
  color: #fff;
  height: 40px;
  width: 100%;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  text-align: center;
}
.login-form .btn-green:hover {
  background-color: #17a398;
}
.btn-forget {
  color: #1BAEA4;
  font-size: 12px;
  text-decoration: none;
  text-align: center;
  display: block;
  margin-top: 10px;
}

/* Center the Login Form */
.pt-pb {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Login Form Responsive */
@media (max-width: 768px) {
  .login-form {
    padding: 20px; /* Less padding on mobile */
    max-width: 100%; /* Full width on mobile */
  }

  .login-form h3 {
    font-size: 18px;
  }

  .login-form input,
  .login-form .btn-green {
    font-size: 14px;
  }

  .btn-forget {
    font-size: 10px;
  }
}

/* Footer */
footer.sticky-bb {
  background-color: #fafafa;
  padding: 10px 0;
  position: absolute;
  width: 100%;
  bottom: 0;
  text-align: center;
}
footer .text {
  color: #4E4D4E;
  font-size: 12px;
}

/* Modal Styles */
.modal-dialog-right {
  position: fixed;
  top: 75px;
  right: 0;
  height: 100%;
  transition: transform 0.3s ease-out;
}
.modal.show .modal-dialog-right {
  transform: translateX(0);
}
.modal-dialog-slide {
  transform: translateX(100%);
}
.modal-backdrop {
  background-color: transparent !important;
  z-index: 1040;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .modal-body {
    padding: 20px;
  }

  .hamburger-menu-box {
    padding: 15px;
  }

  .navbar-nav {
    flex-direction: column;
    margin-top: 20px;
  }
}


.modal {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal.show {
  display: block;
}

.modal-dialog-right {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  height: 100%;
  width: 320px;
  background: #fff;
  transition: transform 0.3s ease-out;
}

.btn-close {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

/* Modal Layout */
.modal-dialog-right {
  position: fixed;
  top: 0;
  right: 0;
  margin: 0;
  height: 100%;
  width: 320px;
  background-color: white;
  box-shadow: -3px 0px 15px rgba(0, 0, 0, 0.1);
  padding: 20px;
  overflow-y: auto;
}

.modal-content {
  border: none;
  border-radius: 0;
  background-color: #fff;
}

.modal-body {
  padding: 0;
}

/* Profile Info */
.profile-info-box {
  text-align: center;
  margin-bottom: 20px;
}

.profile-info-box h3 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}

.profile-info-box h4 {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 10px;
}

.profile-info-box .main-img {
  width: 68px;
  height: 68px;
  border-radius: 50%;
  object-fit: cover;
}

.profile-info-box .small-img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 5px;
}

.margin-l-negative {
  margin-left: -10px;
}

/* Buttons */
.btn-border-green {
  display: block;
  width: 100%;
  text-align: center;
  padding: 10px;
  font-size: 14px;
  color: #1BAEA4;
  border: 1px solid #1BAEA4;
  border-radius: 4px;
  margin-bottom: 10px;
  background-color: #fff;
  cursor: pointer;
  text-decoration: none;
}

.btn-border-green:hover {
  background-color: #1BAEA4;
  color: white;
}

/* List Styling */
ul {
  list-style-type: none; /* Removes the bullet points */
  padding-left: 0; /* Remove any padding */
}

ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #e5e5e5; /* Light border between items */
}

ul li a {
  color: #4E4D4E;
  font-size: 14px;
  text-decoration: none;
  font-weight: 500;
}

ul li a:hover {
  color: #1BAEA4;
}

/* Angle-right icons */
ul li div img {
  width: 12px; /* Adjust the size of the angle-right icon */
  height: auto;
}

/* Modal Close Button */
.btn-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.btn-close:hover {
  color: #1BAEA4;
}

/* Adjust Modal Padding for better layout */
.hamburger-menu-box {
  padding: 20px;
}

/* Modal Layout */
.modal-dialog-right {
  position: fixed;
  top: 0;
  right: 0;
  margin: 0;
  height: 100%;
  width: 320px;
  background-color: white;
  box-shadow: -3px 0px 15px rgba(0, 0, 0, 0.1);
  padding: 20px;
  overflow-y: auto;
}

.modal-content {
  border: none;
  border-radius: 0;
  background-color: #fff;
}

.modal-body {
  padding: 0;
}

/* Profile Info */
.profile-info-box {
  text-align: center;
  margin-bottom: 20px;
}

.profile-info-box h3 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}

.profile-info-box h4 {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 10px;
}

.profile-info-box .main-img {
  width: 68px;
  height: 68px;
  border-radius: 50%;
  object-fit: cover;
}

.profile-info-box .small-img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 5px;
}

.margin-l-negative {
  margin-left: -10px;
}

/* Buttons */
.btn-border-green {
  display: block;
  width: 100%;
  text-align: center;
  padding: 10px;
  font-size: 14px;
  color: #1BAEA4;
  border: 1px solid #1BAEA4;
  border-radius: 4px;
  margin-bottom: 10px;
  background-color: #fff;
  cursor: pointer;
  text-decoration: none;
}

.btn-border-green:hover {
  background-color: #1BAEA4;
  color: white;
}

/* List Styling */
ul {
  list-style-type: none; /* Removes the bullet points */
  padding-left: 0; /* Remove any padding */
  margin: 0; /* Ensure there is no extra margin */
}

ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #e5e5e5; /* Light border between items */
  font-size: 14px;
  color: #4E4D4E;
}

ul li:last-child {
  border-bottom: none; /* Remove bottom border from last item */
}

ul li a {
  color: #4E4D4E;
  font-size: 14px;
  text-decoration: none;
  font-weight: 500;
}

ul li a:hover {
  color: #1BAEA4;
}

/* Arrow Icon Styling */
ul li div img {
  width: 12px; /* Adjust the size of the angle-right icon */
  height: auto;
  display: inline-block;
}

ul li div {
  display: flex;
  align-items: center;
}

/* Modal Close Button */
.btn-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #000;
}

.btn-close:hover {
  color: #1BAEA4;
}

/* Adjust Modal Padding for better layout */
.hamburger-menu-box {
  padding: 20px;
}

/* List Styling */
ul {
  list-style-type: none; /* Removes the bullet points */
  padding-left: 0; /* Remove any padding */
  margin: 0; /* Ensure there is no extra margin */
}

ul li {
  display: flex; /* Use flexbox to align the text and arrow in one line */
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  /* border-bottom: 1px solid #e5e5e5; Light border between items */
  font-size: 14px;
  color: #4E4D4E;
  white-space: nowrap; /* Prevent text from wrapping */
}

ul li:last-child {
  border-bottom: none; /* Remove bottom border from last item */
}

ul li a {
  color: #4E4D4E;
  font-size: 14px;
  text-decoration: none;
  font-weight: 500;
  flex-grow: 1; /* Allow the link to take available space */
}

ul li a:hover {
  color: #1BAEA4;
}

/* Arrow Icon Styling */
ul li div {
  display: flex;
  align-items: center;
  justify-content: flex-end; /* Ensure the arrow stays to the right */
}

ul li div img {
  width: 12px; /* Adjust the size of the angle-right icon */
  height: auto;
  display: inline-block;
  margin-left: 10px; /* Add some space between the text and arrow */
}

/* Password input field styling */
.password-field {
  padding-right: 40px; /* Add space to the right for the icon */
  height: 40px;
  width: 100%; /* Make sure the input field takes full width */
}

/* Icon positioning inside input field */
.togglePassword {
  position: absolute;
  top: 50%; /* Center the icon vertically */
  right: 10px; /* Align the icon to the right */
  transform: translateY(-50%); /* Vertically center the icon */
  cursor: pointer; /* Make the icon clickable */
}

.togglePassword img {
  width: 20px; /* Ensure proper size of the icon */
  height: auto;
  margin-right: 700px;
}

/* Email Verification Screen */
.verification-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Changed from center to flex-start to move it up */
  height: 95vh;
  text-align: center;
  background-color: #f7f7f7;
  padding: 20px;
  padding-top: 80px; /* Add some padding from the top to fine-tune positioning */
}

.alert-icon {
  font-size: 3rem;
  color: #555;
  margin-bottom: 16px;
  font-weight: bold;
}

h2 {
  font-size: 1.6rem;
  color: #333;
  margin-bottom: 8px;
}

.email-address {
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.verification-text {
  font-size: 1rem;
  color: #555;
  margin-bottom: 20px;
  line-height: 1.5;
}

.forgot-password-link {
  color: #007bff;
  text-decoration: none;
  font-size: 0.9rem;
}

.forgot-password-link:hover {
  text-decoration: underline;
}

.alert-icon {
  width: 40px;
  height: 40px;
  background-color: #555;  /* This makes the ellipse dark gray */
  color: white;  /* The exclamation mark color */
  font-size: 24px;  /* Size of the exclamation mark */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;  /* This makes it a perfect circle */
  margin-bottom: 16px;
}

/* Password Reset */
/* General container styling */
.password-reset-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Move container towards the top */
  height: 90vh;
  padding-top: 120px; /* Adjust this value to control the distance from the top */
  text-align: center;
  background-color: #f7f7f7;
  font-family: 'Helvetica Neue', Arial, sans-serif;
}


/* Title styling */
.password-reset-title {
  font-family: 'Zen Kaku Gothic New', sans-serif;
  font-size: 20px;
  color: #4E4D4E  ;
  font-weight: 700;
  line-height: 28.96px;
  text-align: center;
}

/* Instruction text styling */
.password-reset-instructions {
  color: #4E4D4E;
  font-family: 'Zen Kaku Gothic New', sans-serif;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;

  line-height: 25px;
  letter-spacing: 0.05em;
  text-align: center;
}

/* Email input field styling */
.password-reset-email-input {
  width: 100%;
  max-width: 400px;
  padding: 14px;
  font-size: 1rem;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 6px; /* Slightly larger rounding */
  font-family: inherit;
}

/* Button styling */
.password-reset-submit-button {
  background-color: #1BAEA4;
  color: white;
  border: none;
  padding: 14px;
  font-size: 1rem;
  width: 100%;
  max-width: 400px;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500; /* Slightly bold */
  font-family: inherit;
}

/* Hover effect for the button */
.password-reset-submit-button:hover {
  background-color: #1BAEA4;
}

/* Mobile responsive adjustments */
@media (max-width: 768px) {
  .password-reset-title {
    font-size: 1.5rem;
  }

  .password-reset-instructions {
    font-size: 0.9rem;
  }

  .password-reset-email-input {
    font-size: 0.9rem;
    padding: 12px;
  }

  .password-reset-submit-button {
    font-size: 0.9rem;
    padding: 12px;
  }
}

/* User Registration */
/* General form styling */
.form-container {
  width: 100%;
  max-width: 500px;
  margin: 20px auto 0;
  padding: 0;
  text-align: left;
  font-family: 'Arial', sans-serif;
}

.form-title {
  font-size: 24px;
  text-align: center;
  font-weight: 500;
  color: #333;
  margin-bottom: 24px;
}

.form-group {
  margin-bottom: 20px;
}

.form-label {
  display: block;
  font-size: 14px;
  color: #555;
  font-weight: 400;
  margin-bottom: 5px;
  text-transform: lowercase;
}

.form-input {
  width: 100%;
  padding: 12px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

.form-hint {
  font-size: 12px;
  color: #bbb;
  margin-top: 5px;
}

/* Date of Birth input styling */
.dob-inputs {
  display: flex;
  justify-content: space-between;
}

.dob-select {
  flex: 1;
  padding: 12px;
  margin-right: 10px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.dob-select:last-child {
  margin-right: 0;
}

/* Icon setting section */
.icon-setting .upload-box {
  width: 100%;
  padding: 24px;
  border: 1px solid #ddd;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  color: #aaa;
  font-size: 14px;
  background-color: #fafafa;
}

.upload-text {
  font-size: 12px;
  color: #bbb;
}

/* Save Button */
.submit-button {
  width: 100%;
  padding: 14px;
  background-color: #33b28d;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  margin-top: 20px;
}

.submit-button:hover {
  background-color: #28a074;
}

/* Responsive styling for mobile devices */
@media (max-width: 768px) {
  .gallery-detail-main-image {
    width: 100% !important;
  }
  .download-button-container{
    justify-items: center !important;
    margin-right: 81px !important;
  }
  .dob-inputs {
    flex-direction: column;
  }

  .dob-select {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .dob-select:last-child {
    margin-bottom: 0;
  }

  /* Adjust spacing for mobile view */
  .form-container {
    padding: 20px;
  }

  .icon-setting .upload-box {
    padding: 20px;
    font-size: 12px;
  }

  .submit-button {
    padding: 12px;
    font-size: 16px;
    margin-top: 30px;
  }
}

/* Add User Screen */
/* Main container for the screen */
.user-info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Align content towards the top */
  height: 100vh;
  text-align: center;
  padding-top: 100px; /* Move the content down from the top */
  background-color: #f9f9f9;
}

.user-info-title {
  font-size: 20px;
  font-weight: 400;
  color: #666;
  margin-bottom: 40px;
}

/* The circular add button */
.add-user-button {
  width: 82px;
  height: 82px;
  border-radius: 50%;
  background-color: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

/* Plus icon inside the button */
.plus-icon {
  width: 50px;
  height: 50px;
  fill: #fff;
}

/* Text under the button */
.add-user-text {
  font-size: 10px;
  color: #999;
}

/* Responsive styling */
@media (max-width: 768px) {
  .user-info-title {
    font-size: 18px;
    margin-bottom: 30px;
  }

  .add-user-button {
    width: 80px;
    height: 80px;
  }

  .plus-icon {
    width: 40px;
    height: 40px;
  }

  .add-user-text {
    font-size: 13px;
  }

  /* Adjust padding for mobile */
  .user-info-container {
    padding-top: 80px; /* Slightly reduced padding for mobile */
  }
}

/* Mobile Safari fixes for User Registration page */

@media (max-width: 768px) {
  .form-container {
    width: 100%;
    max-width: 100%;
    padding: 20px;
    margin: 0;
    box-sizing: border-box;
  }

  /* Fix input display */
  .form-input,
  .dob-select,
  select.form-input {
    width: 100%;
    height: 44px; /* Increase touch target size */
    padding: 10px;
    font-size: 16px; /* Prevent Safari zoom on focus */
    -webkit-appearance: none; /* Remove Safari default styling */
    margin: 0;
    box-sizing: border-box;
  }

  /* Fix select element appearance */
  select.form-input {
    background-color: #fff;
    background-image: url("data:image/svg+xml;utf8,<svg fill='gray' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/></svg>");
    background-repeat: no-repeat;
    background-position: right 8px center;
    padding-right: 30px; /* Make room for custom arrow */
  }

  /* Fix form groups spacing */
  .form-group {
    margin-bottom: 24px;
    width: 100%;
  }

  /* Fix label display */
  .form-label {
    font-size: 14px;
    margin-bottom: 8px;
    display: block;
  }

  /* Fix date input */
  .dob-inputs {
    width: 100%;
  }

  .dob-select {
    border-radius: 4px;
    margin-bottom: 0;
  }

  /* Fix upload box */
  .icon-setting .upload-box {
    min-height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;
    margin-bottom: 16px;
  }

  /* Fix button */
  .submit-button {
    width: 100%;
    height: 48px;
    margin-top: 32px;
    -webkit-appearance: none;
    border-radius: 4px;
  }

  /* Fix form title */
  .form-title {
    font-size: 20px;
    margin: 16px 0 24px;
    padding: 0 16px;
  }
}

/* iOS specific fixes */
@supports (-webkit-touch-callout: none) {
  .form-input,
  .dob-select,
  select.form-input {
    font-size: 16px !important; /* Prevent zoom */
  }
  
  select::-webkit-datetime-edit {
    font-size: 16px;
  }
  
  /* Fix date input padding */
  input[type="date"] {
    padding: 10px;
    line-height: 24px;
  }
}

.toast-success {
  background-color: #e6f4ea !important; /* Light green for success */
  color: #2e7d32 !important; /* Dark green text */
}

.toast-error {
  background-color: #fdecea !important; /* Light red for error */
  color: #c62828 !important; /* Dark red text */
}

.toast-container {
  z-index: 9999;
}

/* General container and alignment */
.user-selection-screen {
  text-align: center;
  margin-top: 10px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.user-profile-container {
  margin-bottom: 50px;
  justify-items: right;
}

.profile-picture-container {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.profile-picture, 
.child-profile-picture {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-name, 
.child-name {
  font-size: 16px;
  font-weight: bold;
  margin-top: 5px;
  color: #555;
}

/* Section title */
.select-add-user-text {
  font-size: 18px;
  margin-bottom: 20px;
  color: #555;
}

/* Flex layout for user list */
.user-list {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.user-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Add new user button (fixing the design) */
.add-user-button .profile-picture-container {
  width: 90px;
  height: 90px;
  background-color: #e0e0e0; /* Match with reference design */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-user-button .plus-icon {
  width: 40px;
  height: 50px;
}

.add-user-text {
  font-size: 14px;
  color: #555;
  margin-top: 5px;
}

.profile-picture-container {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.profile-picture, 
.child-profile-picture {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Styling for the initials container */
.initials-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  font-weight: bold;
  color: white;
  border-radius: 50%;
}

.select-add-user-text {
  font-size: 18px;
  margin-bottom: 20px;
}

/* Flex layout for user list */
.user-list {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.user-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Add new user button */
.add-user-button .profile-picture-container {
  width: 90px;
  height: 90px;
  background-color: #e0e0e0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-user-button .plus-icon {
  width: 40px;
  height: 40px;
}

.add-user-text {
  font-size: 14px;
  color: #555;
  margin-top: 5px;
}

/* Register Work Form Styles */
.register-work-container {
  max-width: 800px;
  margin: 59px auto;
  width: 100%;
  padding: 20px;
}

.form-title-work {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 30px;
  color: #727272;
  text-align: center;
}

.form-section {
  margin-bottom: 24px;
}

.form-section label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
}

/* Previous styles remain the same... */

.upload-container {
  border: 2px dashed #e0e0e0;
  border-radius: 8px;
  padding: 40px 20px; /* Increased vertical padding */
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  gap: 8px; /* Added consistent spacing between elements */
}

.upload-icon {
  width: 32px; /* Slightly larger icon */
  height: 32px;
  color: #888;
  margin-bottom: 4px;
}

/* Added specific styling for the upload text */
.upload-text-work {
  font-size: 16px;
  color: #888;
}

.upload-limit {
  color: #888;
  font-size: 14px;
}

/* Rest of the styles remain the same... */
.file-input {
  display: none;
}

.text-input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 16px;
}

.text-area {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  min-height: 120px;
  resize: vertical;
  font-size: 16px;
}

.publish-settings {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.toggle-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.toggle {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 26px;
}

.toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #4DB6AC;
}

input:checked + .slider:before {
  transform: translateX(24px);
}

.select-input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 16px;
  background-color: white;
}

.button-group {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 32px;
}

.submit-button {
  padding: 16px;
  background-color: #4DB6AC;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #45a49a;
}

.draft-button {
  padding: 16px;
  background-color: #e0e0e0;
  color: #666;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.draft-button:hover {
  background-color: #d0d0d0;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .register-work-container {
    padding: 16px;
  }

  .form-title-work {
    font-size: 20px;
    margin-bottom: 24px;
  }

  .button-group {
    margin-top: 24px;
  }
  
  .profile-name{
    font-size: small;
  }
}

.publish-settings {
  margin-bottom: 24px;
  display: block;
  flex-direction: column;
  gap: 12px;
}

.publish-settings-title {
  font-size: 16px;
  color: #333;
}

.switches-container {
  display: flex;
  gap: 24px;
  align-items: center;
  margin-top: 10px;
}

.switch-group {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
}

.toggle {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 20px;
}

.toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #4DB6AC;
}

input:checked + .slider:before {
  transform: translateX(16px);
}

.switch-label {
  font-size: 14px;
  color: #666;
  text-align: center;
}

.switch-label.active {
  color: #4DB6AC;
}

.work-labal-class {
  color: #4d4d4d;
  font-weight: 500;
}

.error-message {
  color: #dc2626;
  font-size: 14px;
  margin-top: 4px;
}

.error-input {
  border-color: #dc2626 !important;
}

.error-input:focus {
  border-color: #dc2626 !important;
  box-shadow: 0 0 0 2px rgba(220, 38, 38, 0.1);
}
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #4DB6AC;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.tags-input-container {
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 8px;
  background-color: white;
  min-height: 45px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
}

.tags-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.tag {
  background-color: #f0f9ff;
  border: 1px solid #4DB6AC;
  color: #4DB6AC;
  padding: 4px 8px;
  border-radius: 16px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.tag-remove {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #4DB6AC;
}

.tag-remove:hover {
  color: #e11d48;
}

.tags-input {
  border: none;
  outline: none;
  padding: 4px;
  flex: 1;
  min-width: 120px;
  font-size: 14px;
}

.tags-input-container:focus-within {
  border-color: #4DB6AC;
  box-shadow: 0 0 0 2px rgba(77, 182, 172, 0.1);
}

.tags-input-container.error {
  border-color: #dc2626;
}

.tags-input-container.error:focus-within {
  border-color: #dc2626;
  box-shadow: 0 0 0 2px rgba(220, 38, 38, 0.1);
}

.image-preview {
  position: relative;
  display: inline-block;
  margin: 8px;
}

.image-thumbnail {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 8px;
  border: 1px solid #e5e7eb;
}

.image-remove {
  position: absolute;
  top: -8px;
  right: -8px;
  background: #fff;
  border: 1px solid #e5e7eb;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #6b7280;
  padding: 0;
  line-height: 1;
}

.image-remove:hover {
  background: #f3f4f6;
  color: #e11d48;
}



/*My gallery design */
.gallery-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.gallery-search-section {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 32px;
}

.gallery-search-container {
  position: relative;
}

.gallery-search-icon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #666;
}

.gallery-search-input,
.gallery-category-select,
.gallery-tag-input {
  width: 100%;
  padding: 12px;
  padding-left: 40px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 14px;
}

.gallery-search-button {
  background-color: #4DB6AC;
  color: white;
  padding: 12px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.gallery-search-button:hover {
  background-color: #45a49a;
}

.gallery-profile-section {
  text-align: center;
  margin-bottom: 32px;
}

.gallery-profile-image {
  width: 80px;
  height: 80px;
  margin: 0 auto 16px;
  border-radius: 50%;
  overflow: hidden;
}

.gallery-profile-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gallery-username {
  font-size: 18px;
  margin-bottom: 16px;
}

.gallery-shared-users {
  margin-bottom: 16px;
}

.gallery-user-avatars {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 8px;
}

.gallery-description {
  color: #666;
  font-size: 14px;
  line-height: 1.6;
  max-width: 600px;
  margin: 0 auto;
}

.gallery-title {
  font-size: 24px;
  margin-bottom: 24px;
  text-align: center;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 24px;
  margin-bottom: 32px;
}

.gallery-item {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
}

.gallery-item-date {
  padding: 12px;
  color: #666;
  font-size: 10px;
  margin-left: 195px;
}

.gallery-item-title {
  padding: 0 12px;
  margin-bottom: 12px;
  font-size: 16px;
}

.gallery-item-image {
  width: 100%;
  aspect-ratio: 1;
  object-fit: cover;
}

.gallery-item-description {
  padding: 12px;
  font-size: 14px;
  color: #666;
}

.gallery-item-footer {
  padding: 12px;
  border-top: 1px solid #eee;
  display: flex;
  justify-content: flex-end;
}

.gallery-likes {
  display: flex;
  align-items: center;
  gap: 4px;
}

.gallery-heart-icon {
  cursor: pointer;
  color: #4DB6AC;
}

.gallery-heart-icon.active {
  color: #4DB6AC;
}

.gallery-show-more {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  padding: 12px;
  background: none;
  border: 1px solid #ddd;
  border-radius: 6px;
  cursor: pointer;
  color: #666;
  transition: background-color 0.3s;
}

.gallery-show-more:hover {
  background-color: #f5f5f5;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .gallery-container {
    padding: 16px;
  }

  .gallery-grid {
    grid-template-columns: 1fr;
  }
  
  .gallery-search-section {
    position: sticky;
    top: 0;
    background: white;
    padding: 20px;
    z-index: 10;
  }

  .gallery-profile-section {
    margin-top: 16px;
  }
}


.gallery-search-section {
  background-color: #f8f8f8;
  padding: 33px 0;
  margin-bottom: 40px;
  width: 100%;
}

.gallery-search-container-wrapper {
  width: 90%; /* Increased width */
  max-width: 1400px; /* Increased max-width */
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  align-items: center;
  gap: 20px; /* Slightly increased gap */
}

.gallery-search-input-container {
  position: relative;
  flex: 3; /* Increased flex ratio for search input */
  min-width: 500px; /* Minimum width for search input */
}

.gallery-search-icon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #999;
  width: 16px;
  height: 16px;
}

.gallery-search-input {
  width: 95%;
  height: 40px;
  padding: 8px 12px 8px 36px;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  font-size: 14px;
  background-color: #ffffff;
}

.gallery-dropdown,
.gallery-tag-input {
  height: 40px;
  width: 250px; /* Increased width for dropdowns and tag input */
  padding: 8px 12px;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  font-size: 14px;
  background-color: #ffffff;
}

.gallery-dropdown {
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 24 24' fill='none' stroke='%23999' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 12px center;
  cursor: pointer;
}

.gallery-search-button {
  height: 40px;
  padding: 0 24px; /* Slightly increased padding */
  background-color: #4DB6AC;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  white-space: nowrap;
  cursor: pointer;
  transition: background-color 0.2s;
  min-width: 180px; /* Minimum width for button */
}

.gallery-search-button:hover {
  background-color: #45a49a;
}

/* Mobile styles */
@media (max-width: 768px) {
  .gallery-search-section {
    padding: 20px;
  }

  .gallery-search-container-wrapper {
    flex-direction: column;
    gap: 16px;
    padding: 0;
    width: 100%;
  }

  .gallery-search-input-container {
    width: 100%;
    min-width: unset;
  }

  .gallery-dropdown,
  .gallery-tag-input {
    width: 100%;
  }

  .gallery-search-button {
    width: 100%;
    min-width: unset;
  }
}

.see-more-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 400px;
  margin: 32px auto;
  padding: 12px 20px;
  background: transparent;
  border: 1px solid #4DB6AC;
  border-radius: 4px;
  cursor: pointer;
  gap: 8px;
  transition: all 0.2s ease;
}

.see-more-text {
  color: #4DB6AC;
  font-size: 14px;
  font-weight: 500;
}

.see-more-icon {
  color: #4DB6AC;
  width: 16px;
  height: 16px;
  transition: transform 0.2s ease;
}

.see-more-button:hover {
  background-color: rgba(77, 182, 172, 0.05);
}

.see-more-button:hover .see-more-icon {
  transform: translateY(2px);
}

.gallery-shared-users {
  text-align: center;
  margin: 24px 0;
}

.gallery-shared-users h3 {
  color: #333;
  font-size: 18px;
  margin-bottom: 16px;
  font-weight: 500;
}

.gallery-user-avatars {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
  padding: 0 16px;
}

.user-avatar-container {
  position: relative;
}

.user-avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: white;
  font-size: 16px;
}

.user-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user-avatar-initial {
  background-color: #9333ea; /* Purple for initial avatars */
}

.new-badge {
  position: absolute;
  top: -4px;
  left: -4px;
  background-color: #22c55e;
  color: white;
  font-size: 10px;
  padding: 2px 6px;
  border-radius: 12px;
  font-weight: 500;
}


/*Loader*/
.spinner-fullscreen-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  width: 40px;
  height: 40px;
  animation: spinner-rotate 2s linear infinite;
}

.spinner-circle {
  stroke: #4DB6AC;
  stroke-linecap: round;
  animation: spinner-dash 1.5s ease-in-out infinite;
}

@keyframes spinner-rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spinner-dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

/* Gallery Detail Page */
.gallery-detail-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.gallery-detail-header {
  font-size: 30px;
  color: #333;
  margin-bottom: 24px;
  text-align: center;
}

.gallery-detail-content {
  display: flex;
  gap: 40px;
}

.gallery-detail-image-section {
  flex: 1;
  max-width: 60%;
}

.gallery-detail-main-image {
  position: relative;
  margin-bottom: 20px;
  aspect-ratio: 1;
  background-color: white;
  border-radius: 4px;
  border: 2px solid #e6e0e0; /* Added px solid border */
}

.gallery-detail-main-image img {
  width: 98%;
  height: 100%;
  object-fit: contain;
  border-radius: 7px;
}

.gallery-nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.2s;
  z-index: 2;
}

.gallery-nav-button:hover {
  background: rgba(255, 255, 255, 0.9);
}

.gallery-nav-button.left { left: 16px; }
.gallery-nav-button.right { right: 16px; }

.gallery-detail-info {
  flex: 1;
}

.gallery-detail-author {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 20px;
}

.author-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #4DB6AC;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 500;
}

.author-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.author-avatar.user-avatar-initial {
  background-color: #4DB6AC;
}

.author-name {
  font-size: 16px;
  color: #333;
}

.gallery-detail-title {
  font-size: 24px;
  margin-bottom: 16px;
  color: #333;
  word-break: break-word;
}

.gallery-detail-likes {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 20px;
}

.gallery-heart-icon {
  color: #39a08b;
  cursor: pointer;
}

.gallery-heart-icon.active {
  color: #39a08b;

}

/* Mobile Styles */
@media (max-width: 768px) {
  .gallery-detail-content {
    flex-direction: column;
  }

  .gallery-detail-image-section {
    max-width: 100%;
  }

  .gallery-nav-button {
    width: 32px;
    height: 32px;
  }

  .gallery-detail-title {
    font-size: 20px;
  }
}

.tags-input-container {
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 8px;
  background-color: white;
  min-height: 45px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
}

.tags-input-container.error {
  border-color: #dc2626;
}

.tags-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.tag-badge {
  background-color: #f0f9ff;
  border: 1px solid #4DB6AC;
  color: #4DB6AC;
  padding: 4px 12px;
  border-radius: 16px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.tag-remove-btn {
  background: none;
  border: none;
  color: #4DB6AC;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  font-size: 18px;
}

.tag-remove-btn:hover {
  color: #e11d48;
}

.tags-dropdown {
  flex: 1;
  min-width: 120px;
  border: none;
  outline: none;
  padding: 4px;
  font-size: 14px;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 24px;
  padding: 20px;
}

.gallery-item {
  background: #fff;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.gallery-item-image-container {
  padding: 16px; /* Add padding around image */
}

.gallery-item-image {
  width: 100%;
  aspect-ratio: 1;
  object-fit: cover;
  border-radius: 0px; /* Rounded corners for image */
  cursor: pointer;
}

.gallery-item-info {
  padding: 0 16px 16px;
}

.gallery-item-header {
  display: flex;
  align-items: center;
  gap: 12px;
}

.user-avatar-public {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #82B5B1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
}

.user-avatar-public img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.username {
  font-size: 15px;
  color: #333;
  flex: 1;
}

.gallery-likes {
  display: flex;
  align-items: center;
  gap: 4px;
}

.likes-container {
  display: flex;
  align-items: center;
  gap: 4px;
}

.gallery-heart-icon {
  width: 17px;
  height: 17px;
  color: #4DB6AC; /* Matched heart color */
  cursor: pointer;
}

.gallery-heart-icon.active {
  color: #4DB6AC;
  fill: #4DB6AC;
}

.likes-count {
  color: #4DB6AC;
  font-size: 15px;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 24px;
  padding: 20px;
}



.gallery-item-info {
  padding: 0 16px 16px;
}

.gallery-item-header {
  display: flex;
  align-items: center;
  gap: 12px;
}

.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #82B5B1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
}

.user-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.username {
  font-size: 15px;
  color: #333;
  flex: 1;
}

.gallery-likes {
  display: flex;
  align-items: center;
  gap: 4px;
}

.likes-container {
  display: flex;
  align-items: center;
  gap: 4px;
}

.gallery-item-my {
  position: relative;
  background: #fff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  padding: 16px;
  display: flex;
  flex-direction: column;
}

.gallery-likes-my {
  position: absolute;
  bottom: 16px;
  right: 16px;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0;
}

/* Gallery Detail page */
.gallery-detail-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.gallery-detail-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  margin-top: 24px;
}

.gallery-detail-image-section {
  position: relative;
}

.gallery-detail-main-image {
  width: 100%;
  aspect-ratio: 1;
  margin-bottom: 16px;
}

.gallery-detail-main-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.gallery-nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.8);
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gallery-nav-button.left { left: 16px; }
.gallery-nav-button.right { right: 16px; }

.gallery-detail-thumbnails {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 12px;
}

.thumbnail {
  width: 100%;
  aspect-ratio: 1;
  object-fit: cover;
  cursor: pointer;
  opacity: 0.6;
  transition: opacity 0.2s;
}

.thumbnail.active {
  opacity: 1;
  border: 2px solid #4DB6AC;
}

.gallery-detail-info {
  padding-right: 24px;
}

.gallery-detail-author {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 24px;
}

.author-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #4DB6AC;
  color: white;
  font-weight: 500;
}

.gallery-detail-title {
  font-size: 20px;
  color: #333;
  margin-bottom: 12px;
}

.gallery-detail-likes {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 24px;
  color: #666;
}

.gallery-detail-description {
  color: #666;
  line-height: 1.6;
  margin-bottom: 24px;
  white-space: pre-wrap;
}

.gallery-detail-category,
.gallery-detail-tags {
  margin-bottom: 24px;
}

.gallery-detail-category h3,
.gallery-detail-tags h3 {
  font-size: 16px;
  color: #333;
  margin-bottom: 8px;
}

.tag-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.tag {
  padding: 4px 16px;
  border-radius: 16px;
  background: #fff;
  border: 1px solid #4DB6AC;
  color: #4DB6AC;
  font-size: 14px;
}

@media (max-width: 768px) {
  .gallery-detail-content {
    grid-template-columns: 1fr;
  }
}
.gallery-detail-image-section {
  border: 1px solid #f5f5f5;
  border-radius: 4px;
  background: white;
}

.gallery-detail-main-image {
  width: 127%;
  padding: 16px;
  aspect-ratio: 1;
  position: relative;
  margin-bottom: 0;
}

.gallery-detail-main-image img {
  width: 98%;
  height: 100%;
  object-fit: contain;
}

.gallery-nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 32px;
  height: 32px;
  background: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.gallery-nav-button.left { left: 24px; }
.gallery-nav-button.right { right: 24px; }

.gallery-detail-thumbnails {
  display: flex;
  gap: 16px;
  padding: 0 16px 16px;
}

.thumbnail {
  width: 80px;
  height: 80px;
  object-fit: cover;
  cursor: pointer;
  opacity: 0.6;
  transition: opacity 0.2s;
}

.thumbnail.active {
  opacity: 1;
  border: 2px solid #4DB6AC;
}

.tag-list-detail {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
}

.tag-detail {
  padding: 4px 16px;
  border-radius: 4px;
  font-size: 14px;
  color: #4DB6AC;
  border: 1px solid #4DB6AC;
  background: transparent;
}

/* Base styles */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background: #fff;
  position: relative;
}

.navbar-brand {
  z-index: 2;
}

.navbar-brand img {
  max-width: 150px;
  height: auto;
}

.mobile-menu-toggle {
  display: none;
  background: none;
  border: none;
  color: #333;
  cursor: pointer;
  padding: 0.5rem;
  z-index: 2;
}

.navbar-collapse {
  display: flex;
  align-items: center;
}

.navbar-nav {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-item {
  margin: 0 1rem;
}

.nav-link {
  color: #4e4d4e;
  text-decoration: none !important;
  font-size: 16px;
  font-weight: 600;
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: #4DB6AC;
}

.bottom-login-member-name {
  font-size: 12px;
  color: #4e4d4e;
  white-space: nowrap;
}

/* Mobile styles */
@media (max-width: 768px) {
  .mobile-menu-toggle {
    display: block;
  }

  .navbar-collapse {
    position: fixed;
    top: 0;
    left: -100%;
    width: 80%;
    height: 100vh;
    background: #fff;
    padding: 80px 2rem 2rem;
    transition: left 0.3s ease;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  }
  .user-profile-circle-mobile {
    display: block !important;
    margin-left: auto !important;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #f0f0f0;
    cursor: pointer;
    position: relative;
    margin-left: 15px;
  }
  .navbar-collapse.show {
    left: 0;
  }

  .navbar-nav {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .nav-item {
    margin: 0.5rem 0;
    width: 100%;
  }

  .nav-link {
    display: block;
    padding: 0.5rem 0;
    font-size: 18px;
  }

  .bottom-login-member-name {
    position: static;
    margin-top: 0.25rem;
    display: block;
  }

  .hamburger {
    margin-top: auto;
  }
}

/* Animation for mobile menu */
@keyframes slideIn {
  from { left: -100%; }
  to { left: 0; }
}

@keyframes slideOut {
  from { left: 0; }
  to { left: -100%; }
}

/* Additional utility classes */
.sticky-header {
  position: sticky;
  top: 0;
  z-index: 1000;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.px-md-5 {
  @media (min-width: 768px) {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
}



/* new navbar
/* Desktop Styles */
.desktop-menu {
  display: flex;
  align-items: center;
}

.desktop-hamburger {
  display: block;
}

/* Mobile Menu Styles */
.mobile-menu-toggle {
  display: none;
}

.mobile-menu {
  display: none;
}

/* Media Query for Mobile */
@media (max-width: 768px) {
  .desktop-menu {
    display: none;
  }

  .mobile-menu-toggle {
    display: block;
    background: none;
    border: none;
    padding: 8px;
    cursor: pointer;
  }

  .mobile-menu {
    display: block;
    position: fixed;
    top: 0;
    right: -100%;
    width: 80%;
    height: 100vh;
    background: white;
    transition: right 0.3s ease;
    padding: 80px 24px 24px;
    overflow-y: auto;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  }

  .mobile-menu.show {
    right: 0;
  }

  .mobile-menu-content {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .profile-info-box {
    text-align: center;
    padding: 20px 0;
  }

  .profile-info-box h3 {
    margin-bottom: 16px;
    font-size: 20px;
  }

  .mobile-action-buttons {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .btn-border-green {
    display: block;
    padding: 12px;
    border: 1px solid #4DB6AC;
    border-radius: 4px;
    color: #4DB6AC;
    text-decoration: none;
    text-align: center;
    transition: all 0.3s ease;
  }

  .btn-border-green:hover {
    background: #4DB6AC;
    color: white;
  }

  .mobile-nav-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .mobile-nav-list li {
    border-bottom: 1px solid #eee;
  }

  .mobile-nav-list a {
    display: block;
    padding: 16px 0;
    text-decoration: none;
    font-size: 16px;
  }

  .shared-users-images {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: -8px;
  }

  .small-img {
    width: 40px;
    height: 40px;
    border: 2px solid white;
  }

  .margin-l-negative {
    margin-left: -8px;
  }
}

.modal {
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-dialog-right {
  margin: 0;
  margin-left: auto;
  height: 100%;
}

.modal-content {
  height: 100vh;
  border-radius: 0;
  padding-top: 25px;
}

.modal.show {
  display: block;
}

.modal-dialog-slide {
  transform: translateX(100%);
  animation: slideIn 0.3s forwards;
}

@keyframes slideIn {
  to {
    transform: translateX(0);
  }
}

/* Ensure desktop modal only shows on larger screens */
@media (max-width: 768px) {
  .desktop-hamburger {
    display: none;
  }
}

.mobile-menu {
  display: none;
  position: fixed;
  top: 0;
  right: -100%;
  width: 80%;
  height: 100vh;
  background: white;
  transition: right 0.3s ease;
  padding: 80px 24px 24px;
  overflow-y: auto;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.mobile-menu.show {
  right: 0;
}

.mobile-menu-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.mobile-action-buttons {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.mobile-nav-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.mobile-nav-list li {
  border-bottom: 1px solid #eee;
}

.mobile-nav-list a {
  display: block;
  padding: 16px 0;
  text-decoration: none;
  font-size: 16px;
}

.btn-border-green {
  display: block;
  padding: 12px;
  border: 1px solid #4DB6AC;
  border-radius: 4px;
  color: #4DB6AC;
  text-decoration: none;
  text-align: center;
  transition: all 0.3s ease;
}

.btn-border-green:hover {
  background: #4DB6AC;
  color: white;
}

@media (max-width: 768px) {
  .mobile-menu {
    display: block;
  }

  .desktop-menu {
    display: none;
  }
}

/* Profile section styles */
.profile-info-box {
  text-align: center;
  padding: 20px 0;
}

.profile-info-box h3 {
  margin-bottom: 16px;
}

.shared-users-images {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: -8px;
  margin-top: 12px;
}

.small-img {
  width: 40px;
  height: 40px;
  border: 2px solid white;
  border-radius: 50%;
}

.margin-l-negative {
  margin-left: -8px;
}

.sticky-header {
  padding: 8px 0;  /* Reduced top/bottom padding of entire header */
}

.navbar {
  min-height: 60px;  /* Sets minimum height of navbar */
  padding: 0 15px;   /* Reduced top/bottom padding of navbar */
}

.navbar-brand img {
  margin-top: 10px;
  max-width: 170px;  /* Smaller logo means less height taken up */
  height: auto;      /* Maintains aspect ratio while reducing size */
}

/*User Infformation */
.user-info-container-view {
  max-width: 700px;
  margin: 0 auto;
  width: 100%;
  padding: 40px 20px;
}

.user-info-title {
  font-size: 22px;
  color: #333;
  text-align: center;
  margin-bottom: 48px;
}

.user-info-form {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.user-info-form-group {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.user-info-label {
  font-size: 15px;
  color: #666;
  font-weight: normal;
}

/* Input fields */
.user-info-input {
  width: 100%;
  height: 45px;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 15px;
  color: #333;
}

.user-info-select {
  width: 280px;
  height: 45px;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 15px;
  color: #333;
  background-image: url("data:image/svg+xml,..."); /* Add dropdown arrow */
  background-position: calc(100% - 12px) center;
}

/* Publish settings */
.user-info-toggles {
  display: flex;
  gap: 24px;
  margin-top: 8px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e0e0e0;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  content: "";
  position: absolute;
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #4DB6AC;
}

input:checked + .slider:before {
  transform: translateX(21px);
}

.switch-label {
  font-size: 14px;
  color: #666;
  margin-left: 8px;
}

/* Organization code input */
.user-info-org-input {
  width: 100%;
  height: 45px;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 15px;
  color: #999;
}

.user-info-org-list {
  font-size: 14px;
  color: #666;
  margin-top: 8px;
}

/* Shared range section */
.user-info-shared-range {
  margin-top: 32px;
}

.user-info-users {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  margin-top: 16px;
  text-align: center;
}

.user-info-user {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.user-info-avatar {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  overflow: hidden;
}

.user-info-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user-info-initial {
  background-color: #4DB6AC;
  color: white;
  font-size: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-info-username {
  font-size: 14px;
  color: #666;
  margin: 4px 0;
}

/* Toggle for shared users */
.user-info-share-toggle {
  display: flex;
  align-items: center;
  gap: 8px;
}

.toggle-text {
  font-size: 12px;
  color: #4DB6AC;
}

@media (max-width: 768px) {
  .user-info-container-view {
    padding: 20px;
  }

  .user-info-select {
    width: 100%;
  }

  .user-info-users {
    grid-template-columns: 1fr;
    gap: 32px;
  }
}

/* Account Information */

.info-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px;
}

.info-title {
  font-size: 24px;
  color: #333;
  text-align: center;
  margin-bottom: 40px;
}

.info-form {
  width: 100%;
  max-width: 600px; /* Container for form elements */
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.info-form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.info-label {
  font-size: 16px;
  color: #333;
  font-weight: 500;
}

/* Base input styles */
.info-input,
.info-select {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  color: #333;
  height: 45px; /* Consistent height */
}

/* Specific widths for different fields */
.info-form-group:nth-child(1) .info-input {
  width: 100%;  /* Name field - full width */
}

.info-form-group:nth-child(2) .info-select {
  width: 45%;  /* Address field - shorter */
}

.info-form-group:nth-child(3) .info-input {
  width: 100%;  /* Email field - full width */
}

.info-form-group:nth-child(4) .info-input {
  width: 30%;  /* Password field - shortest */
}

/* Password change button */
.info-change-password {
  width: 45%; /* Match address field width */
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
  height: 45px;
}

.info-change-label {
  font-size: 12px;
  color: #666;
  background: #eee;
  padding: 2px 8px;
  border-radius: 4px;
}

/* Shared users section */
.info-shared-users {
  margin-top: 32px;
}

.info-users-grid {
  display: flex;
  gap: 24px;
  margin-top: 16px;
}

.info-user-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.info-user-avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #4DB6AC;
}

.info-user-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.info-user-initial {
  background-color: #4DB6AC;
  color: white;
  font-size: 24px;
}

.info-username {
  font-size: 14px;
  color: #333;
  text-align: center;
  word-break: break-word;
  max-width: 120px;
}

/* Error states */
.info-input-error {
  border-color: #dc2626;
}

.info-error {
  color: #dc2626;
  font-size: 14px;
  margin-top: 4px;
}

/* Disabled state */
.info-input:disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .info-container {
    padding: 20px;
  }

  .info-title {
    font-size: 20px;
    margin-bottom: 24px;
  }

  .info-form-group:nth-child(2) .info-select,
  .info-form-group:nth-child(4) .info-input,
  .info-change-password {
    width: 100%; /* Full width on mobile */
  }

  .info-users-grid {
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;
  }

  .info-user-avatar {
    width: 50px;
    height: 50px;
  }

  .info-username {
    font-size: 12px;
  }
}

/* Save Information Button */
.info-save-button {
  background-color: #4DB6AC;
  color: white;
  font-size: 16px;
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.info-save-button:hover {
  background-color: #399a8c;
}

.info-save-button:disabled {
  background-color: #b2dfdb;
  cursor: not-allowed;
}

/*contact us*/
.contact-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px;
  width: 100%;
}

.contact-title {
  font-size: 24px;
  color: #333;
  text-align: center;
  margin-bottom: 40px;
}

.contact-form {
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}

.contact-form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.contact-label {
  font-size: 15px;
  color: #666;
  font-weight: normal;
}

.contact-input {
  width: 100%;
  height: 45px;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 15px;
  color: #333;
}

.contact-textarea {
  width: 100%;
  min-height: 200px;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 15px;
  color: #333;
  resize: vertical;
}

.contact-input::placeholder,
.contact-textarea::placeholder {
  color: #999;
  font-size: 14px;
}

.contact-input-error {
  border-color: #dc2626;
}

.contact-error {
  color: #dc2626;
  font-size: 14px;
  margin-top: 4px;
}

.contact-submit {
  width: 100%;
  height: 45px;
  background-color: #4DB6AC;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  margin-top: 16px;
}

.contact-submit:hover {
  background-color: #45a49a;
}

.contact-submit:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {
  .contact-container {
    padding: 20px;
  }

  .contact-title {
    font-size: 20px;
    margin-bottom: 24px;
  }

  .contact-form {
    gap: 16px;
  }

  .contact-textarea {
    min-height: 150px;
  }
}

/* For very small screens */
@media (max-width: 480px) {
  .contact-input,
  .contact-textarea {
    font-size: 14px;
  }

  .contact-label {
    font-size: 14px;
  }
}

/* Contest Page */

.contest-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Search Section */
.contest-search-section {
  display: flex;
  gap: 16px;
  margin-bottom: 40px;
  background: #f8f8f8;
  padding: 20px;
  border-radius: 4px;
}

.contest-search-container {
  position: relative;
  flex: 2;
}

.contest-search-icon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #666;
}

.contest-search-input,
.contest-dropdown,
.contest-tag-input {
  height: 40px;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.contest-search-input {
  width: 100%;
  padding-left: 40px;
}

.contest-dropdown,
.contest-tag-input {
  width: 200px;
}

.contest-search-button {
  padding: 0 20px;
  background: #4DB6AC;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  white-space: nowrap;
}

/* Contest Sections */
.contest-section {
  margin-bottom: 40px;
}

.contest-section-title {
  font-size: 18px;
  margin-top: 20px;
  color: #4E4D4E;
  margin-bottom: 45px;
  text-align: center;
}

.contest-card {
  display: flex;
  gap: 24px;
  margin-bottom: 32px;
}

.contest-image-container {
  width: 300px;
  height: 300px;
}

.contest-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.contest-info {
  flex: 1;
}

.contest-user {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
}

.contest-user-image {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
}

.contest-username {
  font-size: 14px;
  color: #666;
}

.contest-title {
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 1px;
  color: #333;
}

.contest-date {
  font-size: 14px;
  color: #666;
  margin-bottom: 16px;
}

.contest-details-button {
  padding: 8px 24px;
  background: #4DB6AC;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 44px;
}

.contest-see-more {
  width: 200px;
  padding: 12px;
  border: 1px solid #4DB6AC;
  border-radius: 4px;
  background: white;
  color: #4DB6AC;
  cursor: pointer;
  margin-top: 24px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

/* Mobile Responsive */
@media (max-width: 768px) {
  .contest-search-section {
    flex-direction: column;
  }

  .contest-dropdown,
  .contest-tag-input {
    width: 100%;
  }

  .contest-card {
    flex-direction: column;
    gap: 16px;
  }

  .contest-image-container {
    width: 100%;
    height: auto;
    aspect-ratio: 1;
  }

  .contest-info {
    padding: 0 12px;
  }

  .contest-title {
    font-size: 16px;
  }
}

.contest-search-wrapper {
  width: 100vw;
  background: #f8f8f8;
  margin-left: 50%;
  transform: translateX(-50%);
  padding: 20px 0;
}

.contest-search-section {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  gap: 16px;
  padding: 0 20px;
}

.contest-section {
  margin-bottom: 48px;
}

.contest-section-title {
  font-size: 18px;
  color: #333;
  margin-bottom: 24px;
}

.contest-card {
  display: flex;
  gap: 32px;
  margin-bottom: 40px;
}

.contest-image-container {
  width: 400px;
  flex-shrink: 0;
  border-radius: 4px;
  overflow: hidden;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.contest-image {
  width: 100%;
  height: auto;
  display: block;
}

.contest-info {
  flex: 1;
}

.contest-title {
  font-size: 20px;
  color: #333;
  margin-bottom: 1px;
  margin-top: 30px;
  line-height: 1.4;
}

.contest-date {
  font-size: 15px;
  color: #666;
  margin-bottom: 7px;
}

.contest-details-button {
  width: 100%;
  max-width: 400px;
  padding: 12px;
  background: #4DB6AC;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 44px;
  font-size: 14px;
  transition: background-color 0.2s;
}

.contest-user {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 12px;
}

.contest-user-image {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
}

.contest-username {
  font-size: 14px;
  color: #333;
}

/* Section divider */
.contest-divider {
  margin: 40px 0;
  border-top: 1px solid #eee;
}

@media (max-width: 768px) {
  .contest-card {
    flex-direction: column;
    gap: 16px;
  }

  .contest-image-container {
    width: 100%;
  }

  .contest-title {
    font-size: 18px;
    margin-top: 10px;
  }

  .contest-details-button {
    max-width: 100%;
  }
}


/* contest appluy page */
.contest-detail-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
}

.contest-detail-header {
  display: flex;
  gap: 40px;
  margin-bottom: 60px;
}

.contest-detail-image {
  flex: 1;
  max-width: 500px;
}

.contest-detail-image img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.contest-detail-info {
  flex: 1;
}

.contest-detail-user {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 16px;
}

.contest-detail-user img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.contest-detail-title {
  font-size: 24px;
  color: #333;
  margin-bottom: 12px;
  line-height: 1.4;
}

.contest-detail-date {
  color: #666;
  margin-bottom: 24px;
}

.contest-detail-buttons {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 32px;
}

.contest-apply-button,
.contest-upload-button {
  width: 100%;
  padding: 12px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
}

.contest-apply-button {
  background: #4DB6AC;
  color: white;
  border: none;
}

.contest-upload-button {
  background: white;
  color: #4DB6AC;
  border: 1px solid #4DB6AC;
}

.contest-detail-description {
  margin-bottom: 32px;
  color: #666;
  line-height: 1.6;
}

.contest-eligibility {
  margin-bottom: 32px;
}

.contest-eligibility h3 {
  font-size: 16px;
  color: #333;
  margin-bottom: 8px;
}

/* Upload Form Styles */
.contest-upload-section {
  border-top: 1px solid #eee;
  padding-top: 40px;
}

.contest-upload-title {
  font-size: 20px;
  color: #333;
  margin-bottom: 32px;
  text-align: center;
}

.contest-form-group {
  margin-bottom: 24px;
}

.contest-form-group label {
  display: block;
  margin-bottom: 8px;
  color: #333;
}

.contest-upload-container {
  border: 2px dashed #e0e0e0;
  border-radius: 8px;
  padding: 40px;
  text-align: center;
}

.contest-file-input {
  display: none;
}

.contest-upload-area {
  cursor: pointer;
}

.contest-upload-icon {
  color: #666;
  margin-bottom: 12px;
}

.contest-upload-text {
  display: block;
  margin-bottom: 4px;
}

.contest-upload-limit {
  color: #666;
  font-size: 14px;
}

.contest-input,
.contest-textarea,
.contest-select {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.contest-textarea {
  min-height: 120px;
  resize: vertical;
}

.contest-submit-button {
  width: 100%;
  padding: 12px;
  background: #4DB6AC;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .contest-detail-header {
    flex-direction: column;
    gap: 24px;
  }

  .contest-detail-image {
    max-width: 100%;
  }

  .contest-detail-title {
    font-size: 20px;
  }

  .contest-upload-container {
    padding: 20px;
  }
}

/* Add these CSS updates to fix mobile responsiveness */

/* Fix container padding for mobile */
@media (max-width: 768px) {
  .contest-container {
    padding: 20px 16px;
  }

  .contest-search-section {
    padding: 16px;
    margin: 0;
  }

  .contest-search-wrapper {
    width: 100%;
    padding: 16px 0;
  }

  /* Fix search section spacing */
  .contest-search-button {
    padding: 0 12px;
    height: 40px;
    font-size: 13px;
    white-space: normal;
    text-align: center;
    line-height: 1.2;
  }

  /* Adjust card layout for mobile */
  .contest-card {
    padding: 0;
    margin-bottom: 24px;
  }

  .contest-image-container {
    width: 100%;
    height: auto;
    max-height: 300px;
  }

  .contest-info {
    padding: 0;
  }

  /* Fix text content spacing */
  .contest-title {
    font-size: 16px;
    margin-top: 16px;
    padding-right: 16px;
  }

  .contest-date {
    font-size: 13px;
    margin-bottom: 12px;
  }

  /* Fix button alignment */
  .contest-details-button {
    margin-top: 16px;
    width: 100%;
    max-width: none;
  }

  /* Adjust section titles */
  .contest-section-title {
    font-size: 16px;
    margin: 24px 0;
    padding: 0 16px;
  }

  /* Fix search input fields */
  .contest-search-input,
  .contest-dropdown,
  .contest-tag-input {
    font-size: 13px;
  }

  /* Fix see more button */
  .contest-see-more {
    width: 100%;
    max-width: 200px;
    margin: 24px auto;
  }
}

.user-info-save-button {
  width: 100%;
  padding: 12px;
  background: #4DB6AC;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.user-info-save-button:hover {
  background: #399688;
}

.user-info-save-button:disabled {
  background: #B2DFDB;
  cursor: not-allowed;
}

.user-info-org-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.user-info-org-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
}

.user-info-org-tag {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 6px 10px;
  background: #f0f9ff;
  border: 1px solid #4DB6AC;
  border-radius: 4px;
  font-size: 14px;
  color: #4DB6AC;
}

.user-info-org-remove {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background: none;
  border: none;
  color: #4DB6AC;
  cursor: pointer;
  transition: color 0.2s;
}

.user-info-org-remove:hover {
  color: #dc2626;
}

.user-info-error {
  color: #dc2626;
  font-size: 14px;
  margin-top: 4px;
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .user-info-org-tag {
    font-size: 12px;
  }
}


/* Contest Details Page */
 .contest-detail-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
}

.contest-detail-main-title {
  font-size: 24px;
  color: #333;
  text-align: center;
  margin-bottom: 40px;
}

/* .contest-detail-content {
  display: flex;
  gap: 48px;
  align-items: flex-start;
} */

/* .contest-detail-image-section {
  flex: 1;
  max-width: 600px;
} */

.contest-detail-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.contest-detail-info {
  flex: 1;
  max-width: 500px;
}

.contest-detail-user {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 24px;
}

.contest-detail-user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.contest-detail-username {
  font-size: 16px;
  color: #333;
}

.contest-detail-title {
  font-size: 24px;
  color: #333;
  line-height: 1.4;
  margin-bottom: 12px;
}

.contest-detail-date {
  color: #666;
  margin-bottom: 16px;
}

.contest-detail-likes {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 24px;
}

.contest-detail-heart {
  color: #4DB6AC;
  width: 20px;
  height: 20px;
}

.contest-detail-likes-count {
  color: #4DB6AC;
  font-size: 15px;
}

.contest-detail-description {
  color: #666;
  line-height: 1.6;
  margin-bottom: 24px;
  white-space: pre-line;
}

.contest-detail-eligibility {
  margin-bottom: 32px;
}

.contest-detail-eligibility h3,
.contest-detail-entry h3 {
  font-size: 18px;
  color: #333;
  margin-bottom: 12px;
}

.contest-detail-eligibility p {
  color: #666;
}

/* Mobile Styles */
@media (max-width: 768px) {
  /* .contest-detail-content {
    flex-direction: column;
    gap: 32px;
  } */

  .contest-detail-image-section,
  .contest-detail-info {
    max-width: 100%;
  }

  .contest-detail-title {
    font-size: 20px;
  }

  .contest-detail-main-title {
    font-size: 20px;
    margin-bottom: 24px;
  }
}

.contest-detail-content {
  display: flex;
  gap: 48px;
  align-items: flex-start;
}

.contest-detail-image-section {
  width: 500px; /* Fixed width */
  flex-shrink: 0; /* Prevent image section from shrinking */
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  padding: 16px; /* Add padding to match design */
}

.contest-detail-image {
  width: 100%;
  height: 500px; /* Fixed height to maintain aspect ratio */
  object-fit: contain; /* Ensure image fits without distortion */
  border-radius: 4px;
}

.contest-detail-info {
  flex: 1;
  max-width: 600px;
  padding-right: 20px;
}

/* Mobile responsiveness */
@media (max-width: 1024px) {
  .contest-detail-content {
    flex-direction: column;
    align-items: center;
  }

  .contest-detail-image-section {
    width: 100%;
    max-width: 500px; /* Maintain max width on mobile */
  }

  .contest-detail-info {
    padding-right: 0;
    width: 100%;
  }
}

/* Artist List Page */
.artist-list-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Search Section */
.artist-list-search-section {
  background: #f9f9f9;
  padding: 20px;
  margin-bottom: 40px;
  width: 100vw;
  margin-left: 50%;
  transform: translateX(-50%);
}

.artist-list-search-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  gap: 16px;
  align-items: center;
}

.artist-list-search-box {
  position: relative;
  flex: 2;
  display: flex;
  gap: 8px;
}

.artist-list-search-icon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #666;
}

.artist-list-search-input {
  width: 100%;
  height: 40px;
  padding: 8px 12px 8px 36px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.artist-list-filter-group {
  display: flex;
  gap: 8px;
}

.artist-list-filter-btn {
  height: 40px;
  padding: 0 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: white;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  cursor: pointer;
}

.artist-list-dropdown {
  position: absolute;
  top: 100%;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  z-index: 10;
  min-width: 120px;
}

.artist-list-dropdown-item {
  padding: 8px 12px;
  cursor: pointer;
  font-size: 14px;
}

.artist-list-dropdown-item:hover {
  background: #f5f5f5;
}

.artist-list-dropdown-item.active {
  color: #4DB6AC;
}

.artist-list-select,
.artist-list-tag-input {
  height: 40px;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  width: 200px;
}

.artist-list-search-btn {
  height: 40px;
  padding: 0 24px;
  background: #4DB6AC;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  white-space: nowrap;
}

/* Grid Layout */
.artist-list-heading {
  font-size: 24px;
  color: #333;
  text-align: center;
  margin-bottom: 32px;
}

.artist-list-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  margin-bottom: 40px;
}

.artist-list-card {
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  overflow: hidden;
  background: white;
}

.artist-list-card-image-container {
  position: relative;
}

.artist-list-card-image {
  width: 100%;
  height: auto;
  display: block;
}

.artist-list-card-title {
  position: absolute;
  top: 16px;
  left: 16px;
  background: white;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 14px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.artist-list-card-footer {
  padding: 16px;
}

.artist-list-user-info {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;
}

.artist-list-user-avatar {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.artist-list-username {
  font-size: 14px;
  color: #333;
}

.artist-list-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 12px;
}

.artist-list-tag {
  padding: 4px 12px;
  border: 1px solid #ddd;
  border-radius: 16px;
  font-size: 12px;
  color: #666;
}

.artist-list-status {
  margin-left: 110px;
  display: flex;
  gap: 12px;
  font-size: 12px;
}

.artist-list-live,
.artist-list-premium {
  color: #4DB6AC;
  display: flex;
  align-items: center;
  gap: 4px;
}

.artist-list-live::before {
  content: '';
  width: 6px;
  height: 6px;
  background: #4DB6AC;
  border-radius: 50%;
}

.artist-list-see-more {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  padding: 12px;
  border: 1px solid #4DB6AC;
  border-radius: 4px;
  background: white;
  color: #4DB6AC;
  font-size: 14px;
  cursor: pointer;
  margin-top: 32px;
}

/* Mobile Responsive */
@media (max-width: 768px) {
  .artist-list-search-wrapper {
    flex-direction: column;
  }

  .artist-list-search-box {
    flex-direction: column;
  }

  .artist-list-select,
  .artist-list-tag-input {
    width: 100%;
  }

  .artist-list-grid {
    grid-template-columns: 1fr;
  }

  .artist-list-filter-group {
    flex-wrap: wrap;
  }

  .artist-list-filter-btn {
    width: 100%;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .artist-list-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.artist-list-card {
  background: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.artist-list-card-image-container {
  position: relative;
  padding: 16px;
}

.artist-list-card-image {
  width: 100%;
  border-radius: 8px;
  display: block;
}

.artist-list-card-title {
  position: absolute;
  top: 32px;
  left: 32px;
  background: white;
  padding: 8px 16px;
  border-radius: 12px;
  font-size: 16px;
  font-weight: 600;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  line-height: 1.2;
}

.artist-list-card-footer {
  padding: 16px;
}

.artist-list-user-info {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.artist-list-user-left {
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1;
}

.artist-list-user-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.artist-list-username {
  font-size: 15px;
  color: #333;
}

.artist-list-status {
  display: flex;
  margin-left: auto;
  align-items: center;
  gap: 12px;
}

.artist-list-live,
.artist-list-premium {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #4DB6AC;
  font-size: 14px;
}

.artist-list-live::before {
  content: '⚡';
  font-size: 14px;
}

.artist-list-premium::before {
  content: '👑';
  font-size: 14px;
}

.artist-list-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.artist-list-tag {
  padding: 8px 16px;
  border: 1px solid #ddd;
  border-radius: 24px;
  font-size: 14px;
  color: #666;
  background: white;
}

/* Artist Details Page */
.artist-detail-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
}

.artist-detail-title {
  font-size: 24px;
  color: #333;
  text-align: center;
  margin-bottom: 40px;
}

.artist-detail-content {
  display: flex;
  gap: 48px;
}

.artist-detail-left {
  flex: 1;
  max-width: 600px;
}

.artist-detail-image-container {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  padding: 16px;
}

.artist-detail-image {
  width: 100%;
  border-radius: 8px;
  display: block;
}

.artist-detail-image-title {
  position: absolute;
  top: 32px;
  left: 32px;
  background: white;
  padding: 8px 16px;
  border-radius: 12px;
  font-size: 16px;
  font-weight: 600;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.artist-detail-right {
  flex: 1;
  max-width: 500px;
}

.artist-detail-user {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 24px;
}

.artist-detail-user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.artist-detail-username {
  font-size: 16px;
  color: #333;
}

.artist-detail-video-title {
  font-size: 20px;
  color: #333;
  line-height: 1.4;
  margin-bottom: 24px;
}

.artist-detail-description {
  color: #666;
  line-height: 1.6;
  margin-bottom: 32px;
}

.artist-detail-description p {
  margin-bottom: 16px;
}

.artist-detail-meta h3 {
  font-size: 16px;
  color: #333;
  margin-bottom: 12px;
}

.artist-detail-tags {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.artist-detail-tag {
  padding: 4px 16px;
  border: 1px solid #4DB6AC;
  border-radius: 4px;
  color: #4DB6AC;
  font-size: 14px;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .artist-detail-content {
    flex-direction: column;
  }

  .artist-detail-left,
  .artist-detail-right {
    max-width: 100%;
  }

  .artist-detail-image-title {
    top: 24px;
    left: 24px;
    font-size: 14px;
  }

  .artist-detail-video-title {
    font-size: 18px;
  }
}

/* Artist Sign up Detail Page */
.artist-sign-up-detail-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
}

.artist-sign-up-detail-title {
  font-size: 24px;
  color: #333;
  text-align: center;
  margin-bottom: 40px;
}

.artist-sign-up-detail-content {
  display: flex;
  gap: 48px;
}

/* Left Section - Image */
.artist-sign-up-detail-left {
  flex: 1;
  max-width: 600px;
}

.artist-sign-up-detail-image-wrapper {
  position: relative;
  background: white;
  border-radius: 8px;
  overflow: hidden;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.artist-sign-up-detail-image {
  width: 100%;
  border-radius: 8px;
  display: block;
}

.artist-sign-up-detail-image-title {
  position: absolute;
  top: 32px;
  left: 32px;
  background: white;
  padding: 8px 16px;
  border-radius: 12px;
  font-size: 16px;
  font-weight: 600;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

/* Right Section */
.artist-sign-up-detail-right {
  flex: 1;
  max-width: 500px;
}

.artist-sign-up-detail-header {
  margin-bottom: 24px;
}

.artist-sign-up-detail-user {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 16px;
}

.artist-sign-up-detail-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.artist-sign-up-detail-username {
  font-size: 16px;
  color: #333;
}

.artist-sign-up-detail-video-title {
  font-size: 20px;
  color: #333;
  line-height: 1.4;
  margin-bottom: 24px;
}

.artist-sign-up-detail-button {
  width: 100%;
  padding: 16px;
  background-color: #4DB6AC;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.artist-sign-up-detail-button:hover {
  background-color: #45a49a;
}

.artist-sign-up-detail-description {
  color: #666;
  line-height: 1.6;
  margin-bottom: 32px;
}

.artist-sign-up-detail-description p {
  margin-bottom: 16px;
}

.artist-sign-up-detail-footer h3 {
  font-size: 16px;
  color: #333;
  margin-bottom: 12px;
}

.artist-sign-up-detail-tags {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.artist-sign-up-detail-tag {
  padding: 4px 16px;
  border: 1px solid #4DB6AC;
  border-radius: 4px;
  color: #4DB6AC;
  font-size: 14px;
}

/* Mobile Responsive */
@media (max-width: 768px) {
  .artist-sign-up-detail-content {
    flex-direction: column;
  }

  .artist-sign-up-detail-left,
  .artist-sign-up-detail-right {
    max-width: 100%;
  }

  .artist-sign-up-detail-image-title {
    top: 24px;
    left: 24px;
    font-size: 14px;
    padding: 6px 12px;
  }

  .artist-sign-up-detail-video-title {
    font-size: 18px;
  }
}

/* contest-page.css */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.apply-contest-container {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 32px 16px;
}

.apply-contest-card {
  background: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.apply-contest-layout {
  display: flex;
}

.apply-contest-banner {
  width: 50%;
}

.apply-contest-banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.apply-contest-content {
  width: 50%;
  padding: 32px;
}

.apply-contest-user-info {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 16px;
}

.apply-contest-user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.apply-contest-username {
  color: #374151;
}

.apply-contest-title {
  font-size: 24px;
  font-weight: normal;
  color: #374151;
  margin-bottom: 8px;
  line-height: 1.3;
}

.apply-contest-date {
  color: #6B7280;
  margin-bottom: 32px;
}

.apply-contest-button-group {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 32px;
}

.apply-contest-button {
  width: 100%;
  padding: 12px;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.2s ease;
}

.apply-contest-button-primary {
  background-color: #65B3A5;
  color: white;
}

.apply-contest-button-primary:hover {
  background-color: #5aa194;
}

.apply-contest-button-outline {
  background-color: transparent;
  border: 1px solid #65B3A5;
  color: #65B3A5;
}

.apply-contest-button-outline:hover {
  background-color: #f8fcfb;
}

.apply-contest-description {
  margin-bottom: 32px;
  color: #4B5563;
  line-height: 1.6;
}

.apply-contest-description p {
  margin-bottom: 16px;
}

.apply-contest-description p:last-child {
  margin-bottom: 0;
}

.apply-contest-eligibility {
  border-top: 1px solid #E5E7EB;
  padding-top: 16px;
}

.apply-contest-eligibility-title {
  font-size: 16px;
  font-weight: 500;
  color: #374151;
  margin-bottom: 8px;
}

.apply-contest-eligibility-text {
  color: #4B5563;
}

/* Modal Styles */
.apply-contest-modal {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  z-index: 1000;
}

.apply-contest-modal-content {
  background: white;
  border-radius: 8px;
  width: 100%;
  max-width: 672px;
  max-height: 90vh;
  overflow-y: auto;
}

.apply-contest-modal-header {
  padding: 24px;
  border-bottom: 1px solid #E5E7EB;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.apply-contest-modal-title {
  font-size: 24px;
  font-weight: 600;
  color: #111827;
}

.apply-contest-close-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  border-radius: 50%;
}

.apply-contest-close-button:hover {
  background-color: #F3F4F6;
}

.apply-contest-modal-body {
  padding: 24px;
}

.apply-contest-form-group {
  margin-bottom: 24px;
}

.apply-contest-form-label {
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: #374151;
  margin-bottom: 8px;
}

.apply-contest-upload-area {
  border: 2px dashed #E5E7EB;
  border-radius: 8px;
  padding: 32px;
  text-align: center;
  cursor: pointer;
}

.apply-contest-upload-area:hover {
  border-color: #65B3A5;
}

.apply-contest-upload-icon {
  width: 32px;
  height: 32px;
  color: #9CA3AF;
  margin: 0 auto 8px;
}

.apply-contest-upload-text {
  color: #4B5563;
  font-size: 14px;
}

.apply-contest-upload-limit {
  color: #6B7280;
  font-size: 12px;
  margin-top: 4px;
}

.apply-contest-form-input,
.apply-contest-form-textarea,
.apply-contest-form-select {
  width: 100%;
  padding: 12px;
  border: 1px solid #E5E7EB;
  border-radius: 6px;
  font-size: 14px;
}

.apply-contest-form-input:focus,
.apply-contest-form-textarea:focus,
.apply-contest-form-select:focus {
  outline: none;
  border-color: #65B3A5;
  box-shadow: 0 0 0 3px rgba(101, 179, 165, 0.1);
}

.apply-contest-form-textarea {
  min-height: 120px;
  resize: vertical;
}

.apply-contest-toggle-switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 24px;
}

.apply-contest-toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.apply-contest-toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #E5E7EB;
  transition: .4s;
  border-radius: 24px;
}

.apply-contest-toggle-slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

.apply-contest-toggle-switch input:checked + .apply-contest-toggle-slider {
  background-color: #65B3A5;
}

.apply-contest-toggle-switch input:checked + .apply-contest-toggle-slider:before {
  transform: translateX(20px);
}

/* Responsive Styles */
@media (max-width: 768px) {
  .apply-contest-layout {
    flex-direction: column;
  }

  .apply-contest-banner,
  .apply-contest-content {
    width: 100%;
  }

  .apply-contest-banner {
    padding-top: 75%; /* 4:3 Aspect ratio */
    position: relative;
  }

  .apply-contest-banner img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .apply-contest-content {
    padding: 24px;
  }
}
.apply-contest-layout {
  display: flex;
  min-height: 0; /* Important to prevent overflow */
}

.apply-contest-banner {
  width: 50%;
  position: relative;
  height: auto;
  max-height: 600px; /* Adjust this value as needed */
  overflow: hidden;
}

.apply-contest-banner img {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Changed from cover to contain */
  display: block;
}

/* Responsive styles */
@media (max-width: 768px) {
  .apply-contest-layout {
    flex-direction: column;
  }

  .apply-contest-banner {
    width: 100%;
    max-height: 400px; /* Adjust for mobile view */
  }
}


/* Gallery Selection  */
/* gallery-selection-modal.css */
.gallery-selection-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px;
}

.gallery-selection-modal {
  background: white;
  border-radius: 8px;
  width: 100%;
  max-width: 1200px;
  height: 90vh;
  display: flex;
  flex-direction: column;
}

.gallery-selection-modal-header {
  padding: 24px;
  border-bottom: 1px solid #E5E7EB;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.gallery-selection-modal-title {
  font-size: 24px;
  font-weight: 600;
  color: #111827;
}

.gallery-selection-close-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  color: #6B7280;
}

.gallery-selection-close-button:hover {
  background-color: #F3F4F6;
}

.gallery-selection-search {
  padding: 16px 24px;
  border-bottom: 1px solid #E5E7EB;
}

.gallery-selection-search-input {
  position: relative;
  max-width: 400px;
}

.gallery-selection-search-icon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #9CA3AF;
}

.gallery-selection-search-input input {
  width: 100%;
  padding: 12px 12px 12px 40px;
  border: 1px solid #E5E7EB;
  border-radius: 6px;
  font-size: 14px;
}

.gallery-selection-content {
  flex: 1;
  overflow-y: auto;
  padding: 24px;
}

.gallery-selection-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 24px;
}

.gallery-selection-item {
  border: 2px solid transparent;
  border-radius: 8px;
  padding: 16px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.gallery-selection-item:hover {
  border-color: #E5E7EB;
}

.gallery-selection-item.selected {
  border-color: #65B3A5;
  background-color: #F0FDF9;
}

.gallery-selection-item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.gallery-selection-item-date {
  display: flex;
  align-items: center;
  gap: 6px;
  color: #6B7280;
  font-size: 14px;
}

.gallery-selection-likes {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #6B7280;
}

.gallery-selection-likes .liked {
  color: #EF4444;
  fill: #EF4444;
}

.gallery-selection-item-title {
  font-size: 16px;
  font-weight: 500;
  color: #111827;
  margin-bottom: 12px;
}

.gallery-selection-item-image {
  position: relative;
  width: 100%;
  padding-top: 100%;
  margin-bottom: 12px;
  border-radius: 4px;
  overflow: hidden;
}

.gallery-selection-item-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gallery-selection-item-selected {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(101, 179, 165, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.gallery-selection-item-selected span {
  background: #65B3A5;
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  font-weight: 500;
}

.gallery-selection-item-description {
  font-size: 14px;
  color: #4B5563;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.gallery-selection-footer {
  padding: 16px 24px;
  border-top: 1px solid #E5E7EB;
  display: flex;
  justify-content: center;
}

.gallery-selection-submit {
  background: #65B3A5;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.gallery-selection-submit:hover {
  background: #5AA194;
}

.gallery-selection-submit:disabled {
  background: #E5E7EB;
  cursor: not-allowed;
}

/* Gallery selection modal */
.gallery-selection-search {
  padding: 20px;
  border-bottom: 1px solid #E5E7EB;
  display: flex;
  gap: 12px;
  align-items: center;
}

.gallery-selection-search-input {
  position: relative;
  flex: 1;
}

.gallery-selection-search-icon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #9CA3AF;
}

.gallery-selection-search-input input {
  width: 100%;
  padding: 8px 12px 8px 40px;
  border: 1px solid #E5E7EB;
  border-radius: 6px;
  font-size: 14px;
  height: 40px;
}

.gallery-selection-search select,
.gallery-dropdown {
  padding: 8px 12px;
  border: 1px solid #E5E7EB;
  border-radius: 6px;
  font-size: 14px;
  background-color: white;
  min-width: 150px;
  height: 40px;
}

.entry-history-section {
  margin-top: 40px;
  padding: 0 24px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
}

.entry-history-title {
  font-size: 20px;
  color: #333;
  margin-bottom: 24px;
}

.entry-history-slider {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 24px;
  margin: 0 auto;
}

.entry-history-item {
  position: relative;
  background: #fff;
  border-radius: 8px;
  padding: 12px;
  border: 1px solid #E5E7EB;
  cursor: pointer;
}

.entry-history-image {
  width: 100%;
  height: 200px; /* Reduced height */
  object-fit: cover;
  border-radius: 4px;
  margin-bottom: 12px;
  background-color: #F3F4F6;
}

.entry-history-work-title {
  font-size: 14px;
  font-weight: 500;
  color: #333;
  margin-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.entry-history-date {
  font-size: 12px;
  color: #666;
}

.entry-history-image-placeholder {
  width: 100%;
  height: 200px;
  background-color: #F3F4F6;
  border-radius: 4px;
  margin-bottom: 12px;
}

@media (max-width: 1024px) {
  .entry-history-slider {
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  }
  
  .entry-history-image,
  .entry-history-image-placeholder {
    height: 180px;
  }
}

@media (max-width: 768px) {
  .entry-history-slider {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
  
  .entry-history-image,
  .entry-history-image-placeholder {
    height: 160px;
  }
}

@media (max-width: 480px) {
  .entry-history-slider {
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  }
  
  .entry-history-image,
  .entry-history-image-placeholder {
    height: 140px;
  }
  
  .entry-history-section {
    padding: 0 16px;
  }
}
.artist-list-search-section {
  padding: 1rem;
  background: #f8f9fa;
 }
 
 .artist-list-search-wrapper {
  display: flex;
  max-width: 1200px;
  margin: 0 auto; 
 }
 
 .artist-list-search-box {
  display: flex;
  align-items: center;
  width: 100%;
  background: white;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
 }
 
 .search-input-container {
  display: flex;
  align-items: center;
  flex: 1;
  max-width: 200px;
  border-right: 1px solid #e5e7eb;
  padding: 8px 16px;
 }
 
 .artist-list-search-icon {
  width: 20px;
  height: 20px;
  color: #9ca3af;
  margin-right: 12px;
 }
 
 .artist-list-search-input {
  border: none;
  outline: none;
  width: 100%;
  color: #374151;
 }
 
 .artist-list-filter-group {
  display: flex;
  border-right: 1px solid #e5e7eb;
 }
 
 .artist-list-filter-select {
  padding: 8px 24px 8px 16px;
  background: none;
  border: none;
  border-right: 1px solid #e5e7eb;
  min-width: 100px;
  cursor: pointer;
  color: #374151;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 8px center;
  background-size: 16px;
 }
 
 .artist-list-search-btn {
  padding: 8px 24px;
  margin: 8px;
  background: #14b8a6;
  color: white;
  border: none;
  border-radius: 6px;
  white-space: nowrap;
  font-weight: 500;
 }
 
 
@media (max-width: 768px) {
  .artist-list-search-box {
    flex-direction: column;
    width: 100%;
    align-items: stretch;
  }
 
  .search-input-container {
    width: auto;
    border-right: none;
    border-bottom: 1px solid #e5e7eb;
  }
 
  .artist-list-filter-group {
    flex-direction: column;
  }
 
  .artist-list-filter-select {
    border-right: none;
    border-bottom: 1px solid #e5e7eb;
    padding: 12px 16px;
  }
 
  .artist-list-search-btn {
    margin: 12px;
  }
 }
 @media (max-width: 768px) {
  .search-input-container {
    padding: 12px 16px;
  }
 
  .artist-list-search-icon {
    left: 16px;
  }
 }


 /* Confirmation Popup Styles */
.confirm-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
 }
 
 .confirm-popup {
  background: white;
  padding: 24px;
  border-radius: 12px;
  width: 90%;
  max-width: 400px;
  text-align: center;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
 }
 
 .confirm-popup-title {
  font-size: 1.25rem;
  color: #333;
  margin-bottom: 16px;
  font-weight: 600;
 }
 
 .confirm-popup-buttons {
  display: flex;
  gap: 12px;
  justify-content: center;
  margin-top: 24px;
 }
 
 .confirm-popup-button {
  padding: 8px 24px;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
 }
 
 .confirm-popup-confirm {
  background: #14b8a6;
  color: white;
  border: none;
 }
 
 .confirm-popup-confirm:hover {
  background: #0d9488;
 }
 
 .confirm-popup-cancel {
  background: white;
  border: 1px solid #e5e7eb;
  color: #374151;
 }
 
 .confirm-popup-cancel:hover {
  background: #f9fafb;
 }

 /* Link page */
 /* zoom-link.css */
.zoom-link-container {
  max-width: 600px;
  margin: 60px auto;
  padding: 20px;
  text-align: center;
}

.zoom-link-text {
  margin-bottom: 40px;
}

.zoom-link-text h2 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 16px;
  font-weight: 500;
}

.zoom-link-text p {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
  margin-bottom: 8px;
}

.zoom-link-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  padding: 16px;
  background: #14b8a6;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.2s ease;
}

.zoom-link-button:hover {
  background: #0d9488;
}

@media (max-width: 768px) {
  .zoom-link-container {
    margin: 40px auto;
    padding: 16px;
  }

  .zoom-link-text {
    margin-bottom: 32px;
  }

  .zoom-link-button {
    padding: 14px;
    font-size: 0.95rem;
  }
}

@media (max-width: 480px) {
  .zoom-link-container {
    margin: 30px auto;
    padding: 12px;
  }
  
  .zoom-link-text h2 {
    font-size: 1.25rem;
  }

  .zoom-link-text p {
    font-size: 0.9rem;
  }
}

.artist-list-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 24px;
  padding: 20px;
}

.artist-list-card {
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  /* Set fixed height for card */
  height: 360px;
  display: flex;
  flex-direction: column;
}

.artist-list-card-image-container {
  position: relative;
  width: 100%;
  /* Set fixed height for image container */
  height: 220px;
  overflow: hidden;
}

.artist-list-card-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* This ensures image covers the area without distortion */
}

/* video-page.css */
.video-page {
  min-height: 100vh;
  background: #f8f9fa;
  padding: 20px;
}

.video-page-container {
  max-width: 1200px;
  margin: 0 auto;
}

.video-title {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
}

.channel-name {
  background: #dc3545;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

.channel-text {
  color: #333;
  font-size: 14px;
}

.video-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
  height: 0;
}

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

@media (max-width: 768px) {
  .video-page {
    padding: 12px;
  }
  
  .video-wrapper {
    margin-top: 12px;
  }
}

.payment-form {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.form-row {
  margin-bottom: 20px;
}

.form-row label {
  display: block;
  margin-bottom: 8px;
  color: #424770;
  font-weight: 500;
}

.payment-button {
  width: 100%;
  padding: 12px;
  background: #65B3A5;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background 0.2s ease;
}

.payment-button:hover {
  background: #4a5bb9;
}

.payment-button:disabled {
  background: #7887d1;
  cursor: not-allowed;
}

.error-message {
  color: #9e2146;
  margin-bottom: 16px;
  padding: 8px;
  background: #ffebee;
  border-radius: 4px;
}

.payment-form {
  max-width: 400px;
  margin: 0 auto;
  margin-top: 100px;
  padding: 1.5rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.payment-form h2 {
  text-align: center;
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.form-group {
  margin-bottom: 1rem;
}

.error-message {
  color: #E63946;
  text-align: center;
  margin-bottom: 1rem;
}

.success-message {
  color: #2A9D8F;
  text-align: center;
  margin-bottom: 1rem;
}

.submit-button {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  color: white;
  background-color: #65B3A5;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #65B3A5;
}

.submit-button:disabled {
  background-color: #A9A9A9;
  cursor: not-allowed;
}

.payment-page {
  min-height: calc(100vh - 60px);
  background: #f8f9fa;
  padding: 40px 20px;
}

.payment-form {
  max-width: 500px;
  margin: 0 auto;
  padding: 30px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.payment-form h2 {
  text-align: center;
  margin-bottom: 24px;
  color: #333;
}

.amount-display {
  text-align: center;
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 24px;
  padding: 12px;
  background: #f8f9fa;
  border-radius: 8px;
}

.submit-button {
  width: 100%;
  padding: 12px;
  margin-top: 24px;
  background: #14b8a6;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.2s;
}

.submit-button:hover {
  background: #0d9488;
}

.submit-button:disabled {
  background: #e2e8f0;
  cursor: not-allowed;
}

.error-message {
  color: #dc2626;
  background: #fee2e2;
  padding: 12px;
  border-radius: 8px;
  margin-top: 16px;
  font-size: 14px;
  text-align: center;
}

.meeting-link-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.meeting-link-container h3 {
  margin-bottom: 1rem;
  color: #333;
  font-size: 1.5rem;
}

.meeting-link {
  color: #14b8a6;
  font-size: 1.1rem;
  word-break: break-all;
  text-align: center;
  transition: color 0.2s ease;
}

.meeting-link:hover {
  color: #0d9488;
  text-decoration: underline;
}

/* Keep your existing video-wrapper styles for YouTube */
.video-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;
}

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

@media (max-width: 768px) {
  .meeting-link-container {
    padding: 1.5rem;
  }

  .meeting-link-container h3 {
    font-size: 1.25rem;
  }

  .meeting-link {
    font-size: 1rem;
  }
}

/* subscription.css */
.subscription-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
}

.subscription-title {
  text-align: center;
  font-size: 2rem;
  color: #333;
  margin-bottom: 1rem;
}

.subscription-subtitle {
  text-align: center;
  color: #666;
  margin-bottom: 3rem;
  line-height: 1.6;
}

.plans-container {
  display: flex;
  gap: 24px;
  margin-bottom: 4rem;
}

.plan-card {
  flex: 1;
  border: 1px solid #e5e7eb;
  border-radius: 12px;
  padding: 24px;
  background: white;
}

.plan-header {
  text-align: center;
  margin-bottom: 24px;
}

.plan-type {
  color: #65B794;
  margin-bottom: 1rem;
}

.plan-price {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.plan-button {
  padding: 12px 24px;
  border-radius: 6px;
  border: 1px solid #65B794;
  background: white;
  color: #65B794;
  cursor: pointer;
  transition: all 0.3s ease;
}

.plan-button.upgrade {
  background: #65B794;
  color: white;
}

.plan-features ul {
  list-style: none;
  padding: 0;
}

.plan-features li {
  margin-bottom: 1rem;
  padding-left: 24px;
  position: relative;
}

.plan-features li::before {
  content: "•";
  position: absolute;
  left: 0;
  color: #65B794;
}

/* Comparison Table */
.comparison-section {
  margin-top: 4rem;
}

.comparison-title {
  text-align: center;
  margin-bottom: 2rem;
}

.comparison-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 2rem;
}

.comparison-table th,
.comparison-table td {
  padding: 1rem;
  text-align: center;
  border-bottom: 1px solid #e5e7eb;
}

.comparison-table th {
  color: #65B794;
  font-weight: normal;
}

.circle {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #65B794;
  border-radius: 50%;
}

.price-row td {
  font-weight: bold;
  font-size: 1.2rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .plans-container {
    flex-direction: column;
  }

  .subscription-subtitle br {
    display: none;
  }
}

@media (max-width: 480px) {
  .subscription-container {
    padding: 20px 10px;
  }

  .plan-card {
    padding: 16px;
  }

  .comparison-table th,
  .comparison-table td {
    padding: 0.75rem;
    font-size: 0.9rem;
  }
}

.subscription-container {
  max-width: 1200px;
  margin: 40px auto;
  padding: 0 20px;
}

.subscription-title {
  text-align: center;
  font-size: 24px;
  color: #333;
  margin-bottom: 16px;
  font-weight: 500;
}

.subscription-description {
  text-align: center;
  color: #666;
  margin-bottom: 40px;
  line-height: 1.5;
}

.subscription-box {
  display: flex;
  border: 1px solid #65B794;
  border-radius: 12px;
  margin: 0 auto;
  max-width: 1000px;
  background: white;
}

.subscription-half {
  flex: 1;
  padding: 11px;
  position: relative;
}

.subscription-half:first-child {
  border-right: 1px solid #65B794;
}

.subscription-type {
  color: #65B794;
  text-align: center;
  margin-bottom: 24px;
  font-size: 16px;
}

.subscription-price {
  text-align: center;
  font-size: 36px;
  color: #333;
  font-weight: normal;
  margin-bottom: 24px;
}

.subscription-price span {
  font-size: 24px;
  margin-left: 2px;
}

.subscription-button {
  display: block;
  width: 100%;
  padding: 12px;
  border-radius: 4px;
  text-align: center;
  margin-bottom: 32px;
  cursor: pointer;
  font-size: 16px;
}

.subscription-half:first-child .subscription-button {
  border: 1px solid #65B794;
  background: white;
  color: #65B794;
}

.subscription-button.upgrade {
  background: #65B794;
  color: white;
  border: none;
}

.feature-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.feature-list li {
  position: relative;
  padding-left: 16px;
  margin-bottom: 16px;
  color: #333;
  line-height: 1.4;
}

.feature-list li:before {
  content: "•";
  position: absolute;
  left: 0;
  color: #333;
}

@media (max-width: 768px) {
  .subscription-box {
    flex-direction: column;
  }

  .subscription-half:first-child {
    border-right: none;
    border-bottom: 1px solid #65B794;
  }

  .subscription-half {
    padding: 30px 20px;
  }
}

@media (max-width: 480px) {
  .subscription-container {
    margin: 20px auto;
    padding: 0 15px;
  }

  .subscription-description br {
    display: none;
  }
  
  .subscription-price {
    font-size: 30px;
  }
}

.subscription-half ul li {
  border: none !important;
  border-bottom: none !important;
  text-decoration: none !important;
  box-shadow: none !important;
}

/* Add this to your global CSS or create a new CSS file */
.language-switcher {
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 8px 16px;
  border: 1px solid #65B794;
  border-radius: 4px;
  background: white;
  color: #65B794;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 1000;
}

.language-switcher:hover {
  background: #65B794;
  color: white;
}

.nav-language-switcher {
  display: flex;
  align-items: center;
  background: white;
  border: 1px solid #65B794;
  border-radius: 6px;
  padding: 2px;
  margin-left: 15px;
}

.lang-btn {
  background: none;
  border: none;
  padding: 6px 10px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  color: #666;
  transition: all 0.2s ease;
  border-radius: 4px;
}

.lang-btn.active {
  background: #65B794;
  color: white;
}

.lang-btn:hover:not(.active) {
  color: #65B794;
}

.lang-separator {
  color: #e5e7eb;
  margin: 0 2px;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .nav-language-switcher {
    margin: 15px 0;
  }
  
  .mobile-menu .nav-language-switcher {
    justify-content: center;
  }
}

.shared-users-section {
  padding: 20px 0;
  background: #fff;
}

.shared-users-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 16px;
  color: #333;
}

.shared-users-container {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 40px;
}

.shared-users-scroll {
  display: flex;
  gap: 30px;
  overflow-x: auto;
  padding: 10px 0;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.shared-users-scroll::-webkit-scrollbar {
  display: none;
}

.shared-user-preview {
  min-width: 300px;
}

.shared-user-avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
  background: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.shared-user-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.shared-user-info {
  flex: 1;
}

.shared-user-name {
  font-size: 14px;
  margin-bottom: 4px;
  color: #333;
}

.shared-user-link {
  font-size: 12px;
  color: #666;
}

.shared-user-works {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
}

.shared-work-preview {
  aspect-ratio: 1;
  border: 1px solid #eee;
  border-radius: 4px;
  overflow: hidden;
}

.shared-work-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 768px) {
  .shared-users-container {
    padding: 0 20px;
  }
  
  .shared-user-preview {
    min-width: 280px;
  }
}

@media (max-width: 480px) {
  .shared-users-container {
    padding: 0 15px;
  }
  
  .shared-user-preview {
    min-width: 260px;
  }
  
  .shared-work-preview {
    aspect-ratio: 1;
  }
}

.shared-users-section {
  padding: 20px 0;
  background: #fff;
}

.shared-users-scroll {
  display: flex;
  gap: 30px;
  overflow-x: auto;
  padding: 10px 0;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.shared-user-preview {
  min-width: 300px;
  /* Adding fixed width to ensure consistency */
  width: 300px;
}

.shared-user-works {
  display: grid;
  /* Fixed grid with equal columns */
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  /* Adding maximum width to control image container size */
  max-width: 300px;
}

.shared-work-preview {
  /* Force square aspect ratio */
  aspect-ratio: 1;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: relative;
  border: 1px solid #eee;
  border-radius: 4px;
  overflow: hidden;
}

.shared-work-preview img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.shared-users-section {
  padding: 40px 0;
  /* background: #f8f9fa; */
}

.shared-users-title {
  text-align: center;
  margin-bottom: 30px;
  font-size: 18px;
  color: #333;
}

.shared-users-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.shared-users-scroll {
  display: flex;
  gap: 24px;
  overflow-x: auto;
  padding: 10px;
  scrollbar-width: none;
  -ms-overflow-style: none;
  /* Center items when not overflowing */
  justify-content: center;
}

.shared-user-preview {
  background: white;
  border: 1px solid #e5e7eb;
  border-radius: 12px;
  padding: 20px;
  min-width: 320px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.shared-user-header {
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
}

.shared-user-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #f3f4f6;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e5e7eb;
}

.shared-user-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.shared-user-info {
  flex: 1;
}

.shared-user-name {
  font-size: 14px;
  color: #333;
  margin-bottom: 4px;
}

.shared-user-link {
  font-size: 12px;
  color: #6b7280;
}

.shared-user-works {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
}

.shared-work-preview {
  position: relative;
  aspect-ratio: 1;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #e5e7eb;
}

.shared-work-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* For smooth scrolling */
.shared-users-scroll {
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

/* Hide scrollbar */
.shared-users-scroll::-webkit-scrollbar {
  display: none;
}

@media (max-width: 768px) {
  .shared-users-container {
    padding: 0 16px;
  }

  .shared-user-preview {
    min-width: 280px;
  }

  .shared-users-scroll {
    justify-content: flex-start; /* Left align on mobile */
  }
}

.date-label {
  font-weight: 600;
  color: #374151;
  margin-right: 4px;
}

/* CSS to remove underline */


/* Target both the nav-link class and any parent elements */
.desktop-menu .navbar-nav .nav-item .nav-link,
.navbar-nav .nav-item .nav-link,
.nav-link,
a.nav-link {
    text-decoration: none !important;
    border-bottom: none !important;
    position: relative;
    padding-bottom: 0 !important;
    display: inline-block;
}

/* Remove any border effects */
.desktop-menu .navbar-nav .nav-item .nav-link::after,
.desktop-menu .navbar-nav .nav-item .nav-link::before,
.nav-link::after,
.nav-link::before {
    display: none !important;
    content: none !important;
    border: none !important;
    border-bottom: none !important;
    text-decoration: none !important;
}

/* Reset anchor styles */
a {
    text-decoration: none !important;
}

/* Target active state */
.nav-link.active,
.desktop-menu .navbar-nav .nav-item .nav-link.active {
    border-bottom: none !important;
    text-decoration: none !important;
}

/* Override any potential Bootstrap styles */
.navbar .nav-link {
    --bs-nav-link-hover-decoration: none !important;
}

/* Remove bottom border from nav items */
.nav-item {
    border-bottom: none !important;
}

/* Additional reset for any potential Bootstrap classes */
.navbar .nav-link:hover,
.navbar .nav-link:focus,
.navbar .nav-link:active {
    text-decoration: none !important;
    border-bottom: none !important;
}

/* user info */


.shared-users-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  margin-top: 24px;
}

.shared-user-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 12px;
}

.shared-user-avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
}

.shared-user-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.avatar-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #4DB6AC;
  color: white;
  font-size: 24px;
}

.shared-user-name {
  font-size: 14px;
  color: #333;
  margin-bottom: 4px;
}

/* Toggle switch styling */
.shared-user-toggle {
  display: flex;
  align-items: center;
  gap: 8px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 22px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #4DB6AC;
}

input:checked + .slider:before {
  transform: translateX(18px);
}

.toggle-label {
  color: #666;
  font-size: 14px;
}

.toggle-label.active {
  color: #4DB6AC;
}

/* Select styling */
.user-info-select {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 14px;
}

@media (max-width: 768px) {
  .shared-users-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .shared-users-grid {
    grid-template-columns: 1fr;
  }
}

.artist-list-search-section {
  padding: 20px;
  background: #f8f9fa;
}

.artist-list-search-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: 16px;
}

.search-input-container {
  flex: 1;
  position: relative;
  max-width: 400px;
  background: white;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  padding: 8px 12px;
}

.artist-list-search-icon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #9ca3af;
  width: 20px;
  height: 20px;
}

.artist-list-search-input {
  width: 100%;
  border: none;
  outline: none;
  padding-left: 32px;
  font-size: 14px;
}

.artist-list-filter-select {
  height: 53px;
  min-width: 163px;
  padding: 8px 12px;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  background: white;
  font-size: 14px;
  color: #374151;
  cursor: pointer;
  appearance: none;
  background-image: url("data:image/svg+xml,..."); /* Add dropdown arrow */
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 16px;
}

/* Responsive */
@media (max-width: 768px) {
  .artist-list-search-wrapper {
    flex-direction: column;
    gap: 12px;
  }

  .search-input-container {
    max-width: 100%;
  }

  .artist-list-filter-select {
    width: 100%;
  }
}


.artist-list-status {
  display: flex;
  align-items: center;
  gap: 12px;
}

.artist-list-free,
.artist-list-premium,
.artist-list-recorded {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  color: #4DB6AC;
}

.status-icon {
  stroke: #4DB6AC;
  stroke-width: 2;
}

/* Premium specific color */
.artist-list-premium {
  color: #9333EA; /* Purple color for premium */
}

.artist-list-premium .status-icon {
  stroke: #9333EA;
}


.subscription-box {
  display: flex;
  border: 1px solid #65B794;
  border-radius: 12px;
  margin: 0 auto;
  max-width: 1000px;
  background: white;
}

.subscription-half {
  flex: 1;
  padding: 24px;
  position: relative;
}

/* Add vertical line after each plan except the last one */
.subscription-half.with-border::after {
  content: '';
  position: absolute;
  right: 0;
  top: 24px;
  bottom: 24px;
  width: 1px;
  background-color: #65B794;
}

.feature-list {
  list-style: none;
  padding: 0;
  margin: 24px 0 0 0;
}

.feature-list li {
  position: relative;
  padding-left: 20px;
  margin-bottom: 16px;
  line-height: 1.4;
}

.feature-list li:before {
  content: "•";
  position: absolute;
  left: 0;
  color: #65B794;
}

/* Rest of your existing styles */
.subscription-box {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  border: 1px solid #65B794;
  border-radius: 12px;
  margin: 0 auto;
  max-width: 1000px;
  background: white;
  position: relative;
  overflow: hidden;
}

.subscription-half {
  padding: 32px;
  position: relative;
}

/* Border lines between plans */
.subscription-half:not(:last-child) {
  border-right: 1px solid #65B794;
}

/* Plan titles */
.subscription-type {
  font-size: 18px;
  color: #65B794;
  text-align: center;
  margin-bottom: 16px;
}

/* Price styling */
.subscription-price {
  font-size: 32px;
  text-align: center;
  margin-bottom: 24px;
  color: #333;
}

.subscription-price span {
  font-size: 16px;
  color: #666;
}

/* Button styling */
.subscription-button {
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 32px;
  transition: all 0.3s ease;
}

.subscription-button.upgrade {
  background-color: #65B794;
  color: white;
  border: none;
}

.subscription-button:not(.upgrade) {
  background-color: white;
  color: #65B794;
  border: 1px solid #65B794;
}

/* Feature list styling */
.feature-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.feature-list li {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  padding-left: 8px;
  color: #333;
  font-size: 14px;
}

.feature-list li:before {
  content: "•";
  color: #65B794;
  margin-right: 8px;
}

/* Responsive design */
@media (max-width: 768px) {
  .subscription-box {
    grid-template-columns: 1fr;
  }

  .subscription-half:not(:last-child) {
    border-right: none;
    border-bottom: 1px solid #65B794;
  }
}

.subscription-box {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  margin: 0 auto;
  max-width: 1200px;
  background: white;
  position: relative;
  gap: 0;
}

/* Main container border */
.subscription-box::before {
  content: '';
  position: absolute;
  inset: 0;
  border: 1px solid #65B794;
  border-radius: 12px;
  pointer-events: none;
}

.subscription-half {
  padding: 32px;
  position: relative;
}

/* Grid lines between plans */
.subscription-half:not(:last-child)::after {
  content: '';
  position: absolute;
  right: 0;
  top: 32px;
  bottom: 32px;
  width: 1px;
  background-color: #65B794;
}

/* Create row dividers when plans wrap */
.subscription-half:nth-child(n)::before {
  content: '';
  position: absolute;
  left: 32px;
  right: 32px;
  bottom: 0;
  height: 1px;
  background-color: #65B794;
}

.subscription-half:last-child::before {
  display: none;
}

/* Plan type heading */
.subscription-type {
  font-size: 18px;
  color: #65B794;
  text-align: center;
  margin-bottom: 16px;
}

/* Price styling */
.subscription-price {
  font-size: 32px;
  text-align: center;
  margin-bottom: 24px;
}

.subscription-price span {
  font-size: 16px;
  color: #666;
}

/* Button styling */
.subscription-button {
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  margin: 24px 0;
  transition: all 0.2s ease;
}

.subscription-button.upgrade {
  background-color: #65B794;
  color: white;
  border: none;
}

.subscription-button:not(.upgrade) {
  background-color: white;
  color: #65B794;
  border: 1px solid #65B794;
}

/* Feature list */
.feature-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.feature-list li {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  color: #333;
  font-size: 14px;
}

.feature-list li:before {
  content: "•";
  color: #65B794;
  margin-right: 8px;
}

/* Responsive design */
@media (max-width: 1024px) {
  .subscription-box {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .subscription-box {
    grid-template-columns: 1fr;
  }

  .subscription-half::after {
    display: none;
  }

  .subscription-half:not(:last-child)::before {
    left: 16px;
    right: 16px;
    bottom: 0;
  }
}

.subscription-box {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin: 0 auto;
  max-width: 1200px;
  background: white;
  border: 1px solid #65B794;
  border-radius: 12px;
}

.subscription-half {
  padding: 32px;
  position: relative;
}

/* Only add right border to dividers */
.subscription-half:not(:last-child) {
  border-right: 1px solid #65B794;
}

/* Remove any pseudo-elements to prevent double lines */
.subscription-half::before,
.subscription-half::after {
  display: none;
}

/* Rest of the styles remain same */
.subscription-type {
  font-size: 18px;
  color: #65B794;
  text-align: center;
  margin-bottom: 16px;
}

.subscription-price {
  font-size: 32px;
  text-align: center;
  margin-bottom: 24px;
}

.subscription-button {
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  margin: 24px 0;
  transition: all 0.2s ease;
}

/* Responsive */
@media (max-width: 1024px) {
  .subscription-box {
    grid-template-columns: repeat(2, 1fr);
  }
  
  /* Reset borders for 2-column layout */
  .subscription-half {
    border-right: none !important;
    border-bottom: 1px solid #65B794;
  }
  
  .subscription-half:nth-child(odd) {
    border-right: 1px solid #65B794 !important;
  }
  
  .subscription-half:nth-last-child(-n+2) {
    border-bottom: none;
  }
}

@media (max-width: 768px) {
  .subscription-box {
    grid-template-columns: 1fr;
  }
  
  .subscription-half {
    border-right: none !important;
    border-bottom: 1px solid #65B794;
  }
  
  .subscription-half:last-child {
    border-bottom: none;
  }
}

.subscription-box {
  background-color: #FAFAFA;
  /* rest of your existing properties */
}

.subscription-box {
  display: grid;
  /* This will create equal width columns based on available plans */
  grid-template-columns: repeat(auto-fit, minmax(calc(100% / 3), 1fr));
  margin: 0 auto;
  max-width: 1200px;
  background-color: #FAFAFA;
  border: 1px solid #65B794;
  border-radius: 12px;
}

/* For when there are exactly 2 plans */
.subscription-box:has(.subscription-half:nth-child(2):last-child) {
  grid-template-columns: repeat(2, 1fr);
}

/* For when there are exactly 3 plans */
.subscription-box:has(.subscription-half:nth-child(3):last-child) {
  grid-template-columns: repeat(3, 1fr);
}

/* For when there are 4 plans */
.subscription-box:has(.subscription-half:nth-child(4):last-child) {
  grid-template-columns: repeat(4, 1fr);
}

.subscription-half {
  padding: 32px;
  position: relative;
  min-height: 100%;  /* Ensure equal height */
}

.subscription-half:not(:last-child) {
  border-right: 1px solid #65B794;
}

/* Responsive styles */
@media (max-width: 1024px) {
  .subscription-box {
    grid-template-columns: repeat(2, 1fr) !important;
  }
  
  .subscription-half:nth-child(2n) {
    border-right: none;
  }
  
  .subscription-half:not(:nth-last-child(-n+2)) {
    border-bottom: 1px solid #65B794;
  }
}

@media (max-width: 768px) {
  .subscription-box {
    grid-template-columns: 1fr !important;
  }
  
  .subscription-half {
    border-right: none !important;
  }
  
  .subscription-half:not(:last-child) {
    border-bottom: 1px solid #65B794;
  }
}


/*Landing page css
.landing-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Latest Images Strip */
.latest-images-strip {
  border-bottom: 1px solid #eee;
  padding: 20px 0;
  margin-bottom: 40px;
}

.strip-text {
  text-align: center;
  margin-bottom: 20px;
  color: #666;
}

.image-frames {
  display: flex;
  gap: 10px;
  overflow-x: auto;
  padding: 10px 0;
}

.frame {
  min-width: 100px;
  height: 100px;
  background: #f5f5f5;
  border: 1px solid #ddd;
}

/* Features Section */
.features {
  display: flex;
  flex-direction: column;
  gap: 60px;
}

.feature-item {
  display: flex;
  gap: 30px;
  align-items: flex-start;
}

.feature-item.right-aligned {
  flex-direction: row-reverse;
}

.feature-image {
  width: 200px;
  height: 200px;
  background: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
}

.rounded-square {
  border-radius: 10px;
}

.fancy-border {
  border-radius: 30px;
  border-style: double;
}

.dotted-border {
  border-style: dashed;
}

.feature-text h3 {
  color: #65B794;
  margin-bottom: 10px;
  font-weight: normal;
}

.feature-text p {
  color: #666;
}

/* How To Use Section */
.how-to-use {
  text-align: center;
  margin: 80px 0;
}

.steps {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin-top: 40px;
}

.step-image {
  width: 150px;
  height: 150px;
  background: #FFFFFF;
  margin: 0 auto 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}

/* Register Button */
.register-button {
  display: block;
  width: 100%;
  max-width: 400px;
  margin: 40px auto;
  padding: 15px;
  background-color: #FFA500;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

/* Sponsorship Section */
.sponsorship {
  text-align: center;
  margin-top: 80px;
}

.sponsor-logos {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 15px;
  margin: 30px 0;
}

.logo-box {
  background: #f5f5f5;
  padding: 20px;
  text-align: center;
}

.ad-banners {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
}

.ad-banner {
  height: 100px;
  background: #f5f5f5;
  border: 1px solid #ddd;
}

/* Responsive Design */
@media (max-width: 768px) {
  .feature-item,
  .feature-item.right-aligned {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .steps {
    grid-template-columns: 1fr;
  }

  .sponsor-logos {
    grid-template-columns: repeat(2, 1fr);
  }

  .ad-banners {
    grid-template-columns: 1fr;
  }
}
/* Update the features section CSS */
.features {
  display: flex;
  flex-direction: column;
  gap: 60px;
  width: 80%; /* Constrain width to center content */
  margin: 0 auto;
}

.feature-item {
  display: flex;
  justify-content: center;
  width: 100%;
}

.feature-content {
  display: flex;
  gap: 50px;
  align-items: flex-start;
  max-width: 900px; /* Control maximum width */
}

.feature-item:nth-child(even) .feature-content {
  flex-direction: row-reverse;
}

.feature-image {
  width: 200px;
  height: 200px;
  background: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
  flex-shrink: 0; /* Prevent image from shrinking */
}

.feature-text {
  flex: 1;
  max-width: 500px; /* Control text width */
}

.feature-text h3 {
  color: #65B794;
  margin-bottom: 10px;
  font-weight: normal;
}

.feature-text p {
  color: #666;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .features {
    width: 100%;
  }

  .feature-content {
    flex-direction: column !important;
    align-items: center;
    text-align: center;
  }

  .feature-text {
    padding: 0 20px;
  }
}
/* Film strip styling */
.latest-works-container {
  width: 100%;
  margin: 20px 0;
  position: relative;
}

.film-strip-frame {
  width: 100%;
  overflow: hidden;
  background: #F8F8F8;
  padding: 20px 0;
  position: relative;
}

.film-strip-frame::before,
.film-strip-frame::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 20px;
  background: rgba(248, 248, 248, 0.8);
  z-index: 2;
}

.film-strip-frame::before {
  left: 0;
  background: linear-gradient(to right, #F8F8F8, transparent);
}

.film-strip-frame::after {
  right: 0;
  background: linear-gradient(to left, #F8F8F8, transparent);
}

.film-strip {
  display: flex;
  gap: 20px;
  padding: 0 20px;
  overflow-x: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  justify-content: center; /* Centers items when fewer */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.film-strip::-webkit-scrollbar {
  display: none;
}

.film-frame {
  min-width: 150px;
  height: 150px;
  background: #FFFFFF;
  border: 1px solid #E5E7EB;
  border-radius: 4px;
  flex-shrink: 0;
}

/* When there are more items, switch to scrollable */
.film-strip.scrollable {
  justify-content: flex-start;
}

.latest-works-text {
  text-align: center;
  margin-top: 10px;
  color: #666;
  font-size: 14px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .film-frame {
    min-width: 120px;
    height: 120px;
  }

  .film-strip {
    gap: 15px;
    padding: 0 15px;
  }
}

/* Add a class to handle dynamic centering */
.film-strip[data-items="few"] {
  justify-content: center;
}

.film-strip[data-items="many"] {
  justify-content: flex-start;
}

/* Film strip styling */
.latest-works-container {
  width: 100%;
  margin: 20px 0;
  position: relative;
}

.film-strip-frame {
  width: 100%;
  overflow: hidden;
  padding: 20px 0;
  position: relative;
}

.film-strip {
  display: flex;
  gap: 20px;
  padding: 0 20px;
  overflow-x: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  justify-content: center;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.film-strip::-webkit-scrollbar {
  display: none;
}

.film-frame {
  width: 180px; /* Fixed width */
  height: 180px; /* Fixed height */
  background: #FFFFFF;
  border: 1px solid #E5E7EB;
  flex-shrink: 0; /* Prevent shrinking */
  position: relative;
  overflow: hidden; /* Hide overflow */
}

.film-frame img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* This will maintain aspect ratio while covering the space */
  position: absolute;
  top: 0;
  left: 0;
}

.latest-works-text {
  text-align: center;
  margin-top: 10px;
  color: #666;
  font-size: 14px;
}

/* Remove media queries that change image sizes */

.dropdown-container {
  position: relative;
}

.dropdown-container select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-right: 2rem;
  cursor: pointer;
}

.dropdown-icon {
  position: absolute;
  top: 50%;
  right: 0.75rem;
  transform: translateY(-50%);
  color: #6B7280; /* Grey color */
  pointer-events: none;
}

.terms-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background: #FFFFFF;
  border: 1px solid #E5E7EB;
  border-radius: 8px;
}

.terms-container h1 {
  font-size: 24px;
  color: #333333;
  margin-bottom: 20px;
}

.terms-container h2 {
  font-size: 20px;
  color: #333333;
  margin-top: 20px;
  margin-bottom: 10px;
}

.terms-container h3 {
  font-size: 18px;
  color: #333333;
  margin-top: 15px;
  margin-bottom: 10px;
}

.terms-container p {
  font-size: 14px;
  color: #666666;
  line-height: 1.6;
  margin-bottom: 10px;
}

.terms-container ul {
  list-style-type: disc;
  padding-left: 20px;
  margin-bottom: 10px;
}

.terms-container li {
  font-size: 14px;
  color: #666666;
  margin-bottom: 5px;
  white-space: normal;
}

.terms-container .disclaimer {
  font-size: 12px;
  color: #999999;
  margin-top: 20px;
  text-align: center;
}

@media (max-width: 768px) {
  .terms-container {
    padding: 15px;
  }

  .terms-container h1 {
    font-size: 1.75rem;
  }

  .terms-container h2 {
    font-size: 1.25rem;
  }

  .terms-container h3 {
    font-size: 1.125rem;
  }

  .terms-container p,
  .terms-container li {
    font-size: 14px;
  }

  .terms-container .disclaimer {
    font-size: 0.75rem;
  }
}


/* Landing Page */
/* Main Container
.landing-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background: white;
} */

/* Logo Section */
.header {
  text-align: center;
  padding: 20px 0;
}

.japanese-title {
  font-size: 14px;
  color: #333;
  margin-bottom: 10px;
}

.img-fluid-land {
  width: 400px;
  height: auto;
  margin: 15px 0;
}

.japanese-subtitle {
  font-size: 12px;
  color: #666;
  margin-top: 5px;
}



/* Features Section */
.features {
  margin: 60px auto;
  max-width: 900px;
  z-index: 10;
}
.feature-svg-1{
  position: absolute;
  top: 22%;
}
.feature-svg-2{
  position: absolute;
  top: 37%;
}
.feature-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 50px;
  gap: 40px;
}

.feature-item.right {
  flex-direction: row-reverse;
}

.feature-frame {
  width: 220px;
  height: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent !important;
}




.feature-frame.fancy {
  background-image: url('../images/square.png');
  background-size: contain;
}

.feature-frame.dotted {
  background-image: url('../images/oval.png');
  background-size: contain;
}

.feature-text {
  flex: 1;
  padding-top: 20px;
}

.feature-title {
  font-size: 20px;
  margin-bottom: 15px;
  line-height: 1.4;
}

.feature-title.pink { color: #FF69B4; }
.feature-title.blue { color: #4169E1; }
.feature-title.green { color: #2E8B57; }

/* How To Use Section */
.how-to-use {
  margin: 80px 0;
  text-align: center;
}

.section-title {
  font-size: 24px;
  text-align: center;
  margin-bottom: 40px;
  position: relative;
  display: inline-block;
  padding: 0 60px;
}



.steps {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin: 40px 0;
}

.step-frame {
  width: 180px;
  height: 180px;
  margin: 0 auto 20px;
  background: #BDBEBE;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Register Button */
.register-button {
  display: block;
  width: 100%;
  max-width: 400px;
  margin: 40px auto;
  padding: 15px;
  background-color: #F4A460;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 30px;
  cursor: pointer;
}

/* Sponsorship Section */
.sponsor-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 15px;
  margin: 30px 0;
}

.sponsor-box {
  background: #BDBEBE;
  padding: 15px;
  text-align: center;
}

.ad-banners {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 30px;
}

/* Responsive Design */
/* @media (max-width: 768px) {
  .feature-item,
  .feature-item.right {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .steps {
    grid-template-columns: 1fr;
  }

  .sponsor-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .ad-banners {
    grid-template-columns: 1fr;
  }
}

.features {
  margin: 60px auto;
  max-width: 1000px;
  padding: 0 20px;
}

.feature-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 60px;
  gap: 40px;
}

.feature-item.right {
  flex-direction: row-reverse;
}

/* Base frame styles */
.feature-frame {
  flex: 1 0 250px;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  padding: 20px;
}

/* Square frame - first feature */
.feature-frame.square {
  border-radius: 8px;
  margin-left: -209px;
  margin-bottom: -134px;
  box-shadow: none;
  position: relative;
  background-image: url('../../../public/assets/homepageFrame1.jpeg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

/* Oval frame - second feature */
.feature-frame.oval {
  position: relative;
  margin-right: -140px;
  margin-top: -45px;
  background-image: url('../../../public/assets/homepageFrame2.jpeg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

/* Fancy frame - third feature */
.feature-frame.fancy {
  margin-top: -100px;
  margin-left: -209px;
  position: relative;
  background-image: url('../../../public/assets/homepageFrame3.jpeg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

/* Dotted frame - fourth feature */
.feature-frame.dotted {
  margin-top: -45px;
  margin-right: -140px;
  position: relative;
  background-image: url('../../../public/assets/homepageFrame4.jpeg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

/* Image placeholder */
.image-placeholder {
  width: 80%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #000;
}

/* Text styles */
.feature-text {
  flex: 1;
  padding-top: 10px;
  margin-top: 22px;
  font-weight: bold;
}

.feature-title {
  font-size: 24px;
  margin-bottom: 15px;
  line-height: 1.4;
  margin-top: -20px;
  font-weight: bold;
}

.feature-title.pink { color: #FF69B4; }
.feature-title.blue { color: #4169E1; }
.feature-title.green { color: #2E8B57; }

.feature-description {
  font-size: 16px;
  line-height: 1.6;
  color: #666;
}

.user-initial-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
}
@media (max-width: 768px) {
  .feature-item,
  .feature-item.right {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .feature-frame {
    margin-bottom: 20px;
  }

  .section-title {
    padding: 5px;
  }
  .register-button{
    font-size: medium;
  }
}

.ad-banners {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 30px;
  width: 100%;
}

.ad-banner {
  aspect-ratio: 16/9;
  position: relative;
  border: 1px solid #ddd;
  overflow: hidden; /* Prevent image overflow */
  background: white;
}

.ad-banner img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* This ensures the image covers the area without distortion */
  object-position: center; /* Centers the image */
  position: absolute;
  top: 0;
  left: 0;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .ad-banners {
    grid-template-columns: 1fr;
  }
  
  .ad-banner {
    max-width: 100%;
    margin: 0 auto;
  }
}

.film-strip-container {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  background: #DEDFDF;
  padding: 15px 0;
  margin-top: 30px;
  margin-bottom: 30px;
  overflow: hidden; /* Add this to contain the gradients */
}

.film-strip-text {
  text-align: center;
  color: #666;
  font-size: 14px;
  margin-bottom: 20px;
  padding: 0 20px;
}

.film-strip {
  display: flex;
  overflow-x: auto;
  gap: 20px;
  padding: 0 40px; /* Increased padding to prevent first image cutting */
  -ms-overflow-style: none;
  scrollbar-width: none;
  position: relative;
  scroll-behavior: smooth;
}

.film-strip::-webkit-scrollbar {
  display: none;
}

/* Remove the film strip border overlay that was causing issues */
.film-strip::before {
  display: none;
}

.film-frame {
  flex: 0 0 200px;
  height: 200px;
  position: relative;
  overflow: hidden;
  border: none;
  background: white;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
}

.film-frame img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: transform 0.3s ease;
}

.film-frame:hover img {
  transform: scale(1.05);
}



@media (max-width: 768px) {
  .film-frame {
    flex: 0 0 160px;
    height: 160px;
  }
  
  .film-strip {
    padding: 0 30px;
  }
  
  .film-strip-container::before,
  .film-strip-container::after {
    width: 40px;
  }
}


.sponsorship {
  margin: 60px 0;
}

.section-title {
  text-align: center;
  font-size: 24px;
  margin-bottom: 40px;
  position: relative;
}



/* Sponsor Logo Grid */
.sponsor-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 15px;
  margin-bottom: 30px;
  max-width: 1200px;
  margin: 0 auto 30px;
}

.sponsor-box {
  aspect-ratio: 16/9;
  height: 100px; /* Fixed height */
  background: #f5f5f5;
  border: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  overflow: hidden;
}

.sponsor-box img {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Keep aspect ratio */
  max-width: 100%;
  max-height: 100%;
}

/* Ad Banners */
.ad-banners {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  max-width: 1200px;
  margin: 30px auto 0;
}

.ad-banner {
  aspect-ratio: 16/9;
  height: 150px; /* Fixed height */
  border: 1px solid #e0e0e0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ad-banner img {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Keep aspect ratio */
  max-width: 100%;
  max-height: 100%;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .sponsor-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 768px) {
  .sponsor-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .ad-banners {
    grid-template-columns: 1fr;
  }
  
  .sponsor-box {
    height: 80px;
  }
  
  .ad-banner {
    height: 120px;
  }
}
.sponsor-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 15px;
  margin-bottom: 30px;
}

/* Style for when there are only 1 or 2 items */
.sponsor-grid:has(> :nth-child(1):last-child),   /* 1 item */
.sponsor-grid:has(> :nth-child(2):last-child) {  /* 2 items */
  display: flex;
  justify-content: center;
  gap: 15px;
}

.sponsor-box {
  width: 200px;  /* Fixed width for centered items */
  height: 100px;
} 


/* Base container styles */
.landing-container {
  max-width: 1800px;
  margin: 0 auto;
  padding: 20px;
  background: white;
  overflow-x: hidden; /* Prevent horizontal scroll */
}

/* Header Section */
.header {
  text-align: center;
  padding: 20px 0;
  width: 100%;
}



/* Film Strip Section */


@media (max-width: 768px) {
  .film-strip-container {
    margin-left: -20px;
    margin-right: -20px;
    width: calc(100% + 40px);
  }
  
  .film-strip {
    padding: 0 20px;
  }
  
  .film-frame {
    flex: 0 0 140px;
    height: 140px;
  }
}

/* Features Section */
.features {
  margin: 40px auto;
  max-width: 900px;
  padding: 0 15px;
}

@media (max-width: 768px) {
  .features {
    margin: 30px auto;
  }

  .feature-item,
  .feature-item.right {
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 40px;
    gap: 20px;
  }

  .feature-frame {
    width: 180px;
    height: 180px;
    flex: none;
  }

  .feature-text {
    padding-top: 0;
  }

  .feature-title {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .feature-description {
    font-size: 14px;
  }
}

/* How To Use Section */
.how-to-use {
  margin: 60px 0;
  padding: 0 15px;
}

@media (max-width: 768px) {
  .how-to-use {
    margin: 40px 0;
  }

  .steps {
    grid-template-columns: 1fr;
    gap: 30px;
  }

  .step-frame {
    width: 160px;
    height: 160px;
  }

  .step-text {
    font-size: 14px;
  }
}

/* Register Button */
.register-button {
  width: 100%;
  max-width: 700px;
  /* height: 80px; */
  margin: 30px auto;
  background-color: #f49e34;
}

/* Sponsorship Section */
.sponsorship {
  margin: 40px 0;
  padding: 0 15px;
}

@media (max-width: 768px) {
  .sponsor-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

  .sponsor-box {
    height: 80px;
  }

  .ad-banners {
    grid-template-columns: 1fr;
    gap: 15px;
  }

  .ad-banner {
    height: auto;
    aspect-ratio: 16/9;
  }

  .section-title::before,
  .section-title::after {
    width: 60px;
  }

  .section-title::before {
    right: calc(50% + 70px);
  }

  .section-title::after {
    left: calc(50% + 70px);
  }
}

/* Fix for mobile padding and margins */
@media (max-width: 768px) {
  .landing-container {
    padding: 15px;
  }

  .japanese-title {
    font-size: 12px;
  }

  .japanese-subtitle {
    font-size: 10px;
  }
}

/* Ensure images don't overflow on mobile */
img {
  max-width: 100%;
  height: auto;
}

/* Fix for sponsor grid on very small screens */
@media (max-width: 480px) {
  .sponsor-grid {
    grid-template-columns: 1fr;
  }

  .sponsor-box {
    height: 70px;
    margin: 0 auto;
    width: 100%;
    max-width: 200px;
  }
}



/* Mobile-specific adjustments */
@media (max-width: 768px) {
  .film-strip-container {
    /* Keep full width but adjust positioning */
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
  }
  
  .film-strip {
    /* Keep original padding */
    padding: 0 40px;
    gap: 30px;
  }
  
  .film-frame {
    /* Keep original size */
    flex: 0 0 200px;
    height: 200px;
  }
  
  /* Keep gradient overlays the same */
  .film-strip-container::before,
  .film-strip-container::after {
    width: 60px;
  }
}

/* Film Strip Section */




/* Banner Ad Section */
.ad-banners {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 30px;
  width: 100%;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.ad-banner {
  aspect-ratio: 16/9;
  position: relative;
  border: 1px solid #ddd;
  overflow: hidden;
  background: white;
}

.ad-banner img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .film-strip-container {
    width: 100vw;
    margin-left: -50vw;
    margin-right: -50vw;
    left: 50%;
    right: 50%;
  }

  .film-strip {
    -webkit-overflow-scrolling: touch;
    
  }

  /* Banner Ads Mobile Layout */
  .ad-banners {
    grid-template-columns: 1fr;
    gap: 15px;
    padding: 0 15px;
    margin-top: 20px;
  }

  .ad-banner {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
  }

  .ad-banner img {
    max-width: 100%;
    height: auto;
  }
}

/* Sponsorship Section */
.sponsorship {
  margin: 60px 0;
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.sponsor-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 15px;
  margin-bottom: 30px;
}

.sponsor-box {
  aspect-ratio: 16/9;
  height: 100px;
  background: #f5f5f5;
  border: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  overflow: hidden;
}

.sponsor-box img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
}

@media (max-width: 768px) {
  .sponsor-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    padding: 0 15px;
  }

  .sponsor-box {
    height: 80px;
  }
}


.film-strip-container {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  background: #DEDFDF;
  padding: 15px 0;
  margin-top: 30px;
  margin-bottom: 30px;
  overflow: hidden;
}

.film-strip {
  display: flex;
  overflow-x: auto;
  gap: 50px;
  padding: 0 40px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  position: relative;
  scroll-behavior: smooth;
  margin-left: 20px;  /* Added margin to prevent first image cut-off */
}

.film-frame {
  flex: 0 0 200px;
  height: 200px;
  position: relative;
  overflow: hidden;
  border: none;
  background: white;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
}

.terms-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background: #FFFFFF;
  border: 1px solid #E5E7EB;
  border-radius: 8px;
  overflow-x: hidden;
  width: auto;
}

.terms-container h1 {
  font-size: 24px;
  color: #333333;
  margin-bottom: 20px;
  line-height: 1.4;
}

.terms-container h2 {
  font-size: 20px;
  color: #333333;
  margin-top: 30px;
  margin-bottom: 15px;
}

.terms-container h3 {
  font-size: 18px;
  color: #333333;
  margin-top: 20px;
  margin-bottom: 10px;
}

.terms-container p {
  font-size: 14px;
  color: #666666;
  line-height: 1.6;
  margin-bottom: 15px;
  word-wrap: break-word;
}

.terms-container ul {
  list-style-type: disc;
  padding-left: 20px;
  margin-bottom: 15px;
  margin-top: 10px;
}

.terms-container li {
  font-size: 14px;
  color: #666666;
  margin-bottom: 8px;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .terms-container {
    margin: 0;
    padding: 15px;
    border-radius: 0;
    border-left: none;
    border-right: none;
    width: 100%;
  }

  .terms-container h1 {
    font-size: 20px;
    margin-bottom: 15px;
  }

  .terms-container h2 {
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 12px;
  }

  .terms-container h3 {
    font-size: 16px;
    margin-top: 15px;
    margin-bottom: 8px;
  }

  .terms-container p {
    font-size: 14px;
    margin-bottom: 12px;
    padding-right: 0;
  }

  .terms-container ul {
    padding-left: 18px;
  }

  .terms-container li {
    font-size: 14px;
    margin-bottom: 6px;
  }
}


.terms-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  background: #F9FAFB;
}

.terms-header {
  max-width: 800px;
  margin: 0 auto 40px;
  text-align: center;
}

.terms-header h1 {
  font-size: 32px;
  color: #111827;
  margin-bottom: 16px;
  font-weight: 600;
}

.terms-header .last-updated {
  font-size: 14px;
  color: #6B7280;
  margin-bottom: 24px;
  font-style: italic;
}

.terms-header .welcome-text,
.terms-header .agreement-text {
  font-size: 16px;
  color: #4B5563;
  line-height: 1.6;
  margin-bottom: 16px;
  text-align: left;
}

.terms-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px;
  background: #FFFFFF;
  border: 1px solid #E5E7EB;
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.terms-section {
  margin-bottom: 40px;
}

.terms-section h2 {
  font-size: 24px;
  color: #111827;
  margin-bottom: 24px;
  font-weight: 600;
}

.terms-subsection {
  margin-bottom: 24px;
}

.terms-subsection h3 {
  font-size: 18px;
  color: #374151;
  margin-bottom: 16px;
  font-weight: 500;
}

.terms-container p {
  color: #4B5563;
  line-height: 1.6;
  margin-bottom: 16px;
}

.terms-container ul {
  font-size: 14px;
  list-style-type: disc;
  padding-left: 24px;
  margin-bottom: 16px;
}

.terms-container li {
  font-size: 14px;
  color: #4B5563;
  margin-bottom: 8px;
  line-height: 1.6;
}

.contact-info {
  background: #F9FAFB;
  padding: 24px;
  border-radius: 8px;
  margin-top: 16px;
}

.company-info {
  font-weight: 500;
  color: #111827;
}

.final-agreement {
  margin-top: 40px;
  padding-top: 24px;
  border-top: 1px solid #E5E7EB;
  font-weight: 500;
}

@media (max-width: 768px) {
  .terms-page {
    padding: 20px 16px;
  }

  .terms-header {
    margin-bottom: 24px;
  }

  .terms-header h1 {
    font-size: 24px;
    margin-bottom: 12px;
  }

  .terms-header .welcome-text,
  .terms-header .agreement-text {
    font-size: 14px;
    margin-bottom: 12px;
  }

  .terms-container {
    padding: 24px 16px;
    border-radius: 8px;
  }

  .terms-section {
    margin-bottom: 32px;
  }

  .terms-section h2 {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .terms-subsection h3 {
    font-size: 16px;
    margin-bottom: 12px;
  }

  .terms-container p,
  .terms-container li {
    font-size: 14px;
    margin-bottom: 12px;
  }

  .terms-container ul {
    padding-left: 20px;
  }

  .contact-info {
    padding: 16px;
  }

  .final-agreement {
    margin-top: 32px;
    padding-top: 20px;
  }
}


.film-strip-container {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  background: #DEDFDF;
  padding: 15px 0;
  margin-top: 30px;
  margin-bottom: 30px;
  overflow: hidden;
}

.film-strip-text {
  text-align: center;
  color: #666;
  font-size: 14px;
  margin-bottom: 20px;
  padding: 0 20px;
}

.film-strip-wrapper {
  position: relative;
  overflow: hidden;
  padding: 0 40px;
}

.film-strip {
  display: flex;
  gap: 20px;
  padding: 0 40px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  position: relative;
  transition: transform 0.1s linear;
}

.film-strip::-webkit-scrollbar {
  display: none;
}

.film-frame {
  flex: 0 0 200px;
  height: 200px;
  position: relative;
  overflow: hidden;
  border: none;
  background: white;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.film-frame img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: transform 0.3s ease;
}

.film-frame:hover img {
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .film-frame {
    flex: 0 0 160px;
    height: 160px;
  }
  
  .film-strip {
    padding: 0 30px;
  }
  
  .film-strip-wrapper {
    padding: 0 30px;
  }
}


.film-strip-container {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  background: #DEDFDF;
  padding: 15px 0;
  margin-top: 30px;
  margin-bottom: 30px;
  overflow: hidden;
}

.film-strip-text {
  text-align: center;
  color: #666;
  font-size: 14px;
  margin-bottom: 20px;
  padding: 0 20px;
}

.film-strip-wrapper {
  position: relative;
  overflow: hidden;
  margin: 0 -20px; /* Negative margin to counter the gap */
}

.film-strip {
  display: flex;
  gap: 20px;
  position: relative;
  transition: transform 0.1s linear;
  padding: 0 20px; /* Reduced padding to prevent empty spaces */
  will-change: transform; /* Optimize animation performance */
}

.film-frame {
  flex: 0 0 200px;
  height: 200px;
  position: relative;
  overflow: hidden;
  border: none;
  background: white;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.film-frame img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: transform 0.3s ease;
}

.film-frame:hover img {
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .film-frame {
    flex: 0 0 160px;
    height: 160px;
  }
  
  .film-strip {
    gap: 15px;
  }
  
  .film-strip-wrapper {
    margin: 0 -15px;
  }
}

.film-strip-container {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  background: #DEDFDF;
  padding: 15px 0;
  margin-top: 30px;
  margin-bottom: 30px;
  overflow: hidden;
}

.film-strip-text {
  text-align: center;
  color: #666;
  font-size: 14px;
  margin-bottom: 20px;
  padding: 0 20px;
}

.film-strip-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.film-strip {
  display: flex;
  gap: 20px;
  position: relative;
  padding: 0 20px;
  transition: transform 0.1s linear;
  will-change: transform;
  width: max-content; /* Ensures the strip takes full width of content */
}

.film-frame {
  flex: 0 0 200px;
  width: 200px; /* Explicit width to ensure consistent sizing */
  height: 200px;
  position: relative;
  overflow: hidden;
  border: none;
  background: white;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.film-frame img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: transform 0.3s ease;
}

.film-frame:hover img {
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .film-frame {
    flex: 0 0 160px;
    width: 160px;
    height: 160px;
  }
  
  .film-strip {
    gap: 15px;
    padding: 0 15px;
  }
}


.how-to-use {
  margin: 80px auto;
  text-align: center;
  max-width: 1200px;
  padding: 0 20px;
}

.title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  gap: 20px;
}

.divider {
  width: 120px;
  height: 20px;
  object-fit: contain;
}

.section-title {
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 2px;
  margin-bottom: -7px
}

.subtitle {
  font-size: 14px;
  color: black;
  font-weight: bold;
  margin-bottom: 18px;
}

.steps {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  max-width: 1000px;
  margin: 0 auto;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.step-number {
  width: 80px;
  height: auto;
  margin-bottom: 20px;
}

.step-frame {
  width: 280px;
  height: 280px;
  margin-bottom: 20px;
  background: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
}

.step-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.step-text {
  font-size: 16px;
  line-height: 1.6;
  color: black;
  font-weight: bold;
}

.note {
  font-size: 12px;
  color: #666;
  display: block;
  margin-top: 5px;
}

@media (max-width: 1024px) {
  .step-frame {
    width: 240px;
    height: 240px;
  }
}

@media (max-width: 768px) {
  .how-to-use {
    margin: 40px 0;
  }

  .title-container {
    gap: 15px;
  }

  .divider {
    width: 80px;
  }

  .section-title {
    font-size: 24px;
  }

  .steps {
    grid-template-columns: 1fr;
    gap: 40px;
  }

  .step-frame {
    width: 200px;
    height: 200px;
  }

  .step-text {
    font-size: 14px;
  }
}


/* feature */
@media (max-width: 768px) {
  .features {
    margin: 20px auto;
    padding: 0 15px;
    overflow: visible;
  }

  .feature-item,
  .feature-item.right {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
    position: relative;
    width: 100%;
  }

  .feature-frame {
    width: 270px;
    /* height: 140px; */
    flex: none;
    margin: 0;
  }

  /* Clear any existing margins */
  .feature-frame.square,
  .feature-frame.oval,
  .feature-frame.fancy,
  .feature-frame.dotted {
    margin: 0;
  }

  .feature-text {
    width: 100%;
    padding: 0;
    margin: 15px 0 0 0;
    text-align: center;
    position: relative;
  }

  .feature-title {
    font-size: 15px;
    margin: 0 0 8px 0;
    line-height: 1.5;
    width: 100%;
    display: block;
  }

  .feature-description {
    font-size: 13px;
    line-height: 1.5;
    width: 100%;
    display: block;
    margin: 0;
  }

  /* Reset all custom margins and styles */
  .feature-text[style],
  .feature-title[style],
  .feature-description[style] {
    margin: 0 !important;
    margin-top: 0 !important;
    margin-left: 0 !important;
  }

  /* Ensure line breaks work correctly */
  br {
    display: inline-block;
    content: "";
    margin: 2px 0;
  }

  /* Add proper spacing between features */
  .feature-item:not(:last-child) {
    margin-bottom: 40px;
  }
}

.prohibited-content {
  padding: 20px;
}

.prohibited-content h2 {
  font-size: 24px;
  margin-bottom: 15px;
}

.prohibited-content p {
  margin-bottom: 20px;
}

.prohibited-content ul {
  padding-left: 20px;
  margin: 0;
  width: 100%;
}

.prohibited-content li {
  margin-bottom: 15px;
  line-height: 1.5;
  white-space: normal;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  padding-right: 15px; /* Add padding to prevent text from touching the right edge */
}

@media screen and (max-width: 768px) {
  .prohibited-content li {
    font-size: 14px;
    max-width: calc(100% - 20px); /* Ensure text has room for wrapping */
  }
  
  .prohibited-content h2 {
    font-size: 20px;
  }
}

html, body {
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.landing-container {
  width: 100%;
  max-width: 1800px;
  margin: 0 auto;
  padding: 20px;
  background: white;
  box-sizing: border-box;
  position: relative;
}

.header {
  width: 100%;
  text-align: center;
  padding: 20px 0;
  box-sizing: border-box;
}

.auth-btn {
  color: #1baea5;
}

@media screen and (max-width: 768px) {
  .landing-container {
    padding: 15px;
    width: 100%;
    left: 0;
    right: 0;
  }
}
.user-profile-circle-mobile {
  display: none;
}
.user-profile-circle-mobile img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}
.user-profile-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #f0f0f0;
  cursor: pointer;
  position: relative;
  margin-left: 15px;
}

.user-profile-circle img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.sub-users-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  padding: 10px;
  display: none;
  z-index: 1000;
  min-width: 200px;
}

.user-profile-circle:hover .sub-users-dropdown {
  display: block;
}
.user-profile-circle-mobile:hover .sub-users-dropdown {
  display: block;
}

.sub-user-item {
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
}

.sub-user-item img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

.sub-users-dropdown {
  max-height: 250px; /* Height for 5 items */
  overflow-y: auto;
  left: -255%;
}

/* Scrollbar styling */
.sub-users-dropdown::-webkit-scrollbar {
  width: 6px;
}

.sub-users-dropdown::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.sub-users-dropdown::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.mobile-nav-container {
  display: flex;
  align-items: center;
  gap: 15px;
}

.mobile-profile-circle {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  margin: auto;
}

.mobile-profile-circle img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.mobile-sub-users {
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  padding: 10px;
  z-index: 1000;
  min-width: 200px;
  max-height: 300px;
  overflow-y: auto;
}

.mobile-sub-user-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px;
  cursor: pointer;
}

.mobile-sub-user-item img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

/* Desktop dropdown */
.sub-users-dropdown {
  border: 1px solid #000;
  border-radius: 8px;
 }
 
 .sub-user-item {
  border-bottom: 1px solid #000;
  padding: 12px;
 }
 
 .sub-user-item:last-child {
  border-bottom: none;
 }
 
 /* Mobile dropdown */
 .mobile-sub-users {
  border: 1px solid #000;
  border-radius: 8px;
 }
 
 .mobile-sub-user-item {
  border-bottom: 1px solid #000;
  padding: 12px;
 }
 
 .mobile-sub-user-item:last-child {
  border-bottom: none;
 }

 .sub-user-item, .mobile-sub-user-item {
  display: grid;
  grid-template-columns: 40px 1fr;
  align-items: center;
  gap: 15px;
  padding: 12px;
  border-bottom: 1px solid #000;
 }
 
 .sub-user-item img, .mobile-sub-user-item img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
 }
 
 .sub-user-item span, .mobile-sub-user-item span {
  font-size: 14px;
 }

 .profile-actions {
  position: absolute;
  top: -5px;
  right: -5px;
  z-index: 1;
}

.delete-btn {
  background: grey;
  color: white;
  width: 100%;
  padding: 12px;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  margin-top: 10px;
  transition: background 0.3s ease;
}
/* Overlay background */
.delete-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal container */
.delete-modal-content {
  background: #fff;
  padding: 24px;
  border-radius: 8px;
  width: 400px;
  text-align: center;
  position: relative;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* Close button */
.close-btn {
  position: absolute;
  top: 12px;
  right: 12px;
  background: transparent;
  border: none;
  cursor: pointer;
}

/* Delete confirmation checkbox */
.delete-confirm-checkbox {
  display: flex;
  align-items: center;
  margin-top: 15px;
  font-size: 14px;
}

.delete-confirm-checkbox input {
  margin-right: 10px;
  cursor: pointer;
}

/* Action buttons */
.delete-modal-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.cancel-btn {
  background: #ccc;
  color: #333;
  border: none;
  padding: 10px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.delete-confirm {
  background: #d9534f;
  color: #fff;
  border: none;
  padding: 10px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.2s;
}

.delete-confirm:disabled {
  background: #f2a3a0;
  cursor: not-allowed;
}

.delete-confirm:hover:not(:disabled) {
  background: #c9302c;
}

.delete-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.delete-modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.delete-modal-actions {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 10px;
}


.info-delete-button {
  background: #dc3545;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  margin-top: -7px;
  width: 100%;
}

.info-delete-button:hover {
  background: #c82333;
}

/* Footer styles */
.footer {
  padding: 20px 0;
  position: relative;
  width: 100%;
  border-top: outset;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

/* Left side - Logo */
.footer-left {
  flex: 1;
}

.footer-logo {
  width: 120px;
}

/* Center - Links */
.footer-center {
  flex: 2;
  text-align: center;
}

.footer-link {
  color: black;
  text-decoration: none;
  margin: 0 10px;
  font-size: 14px;
  white-space: nowrap;
}

.footer-link:hover {
  text-decoration: underline;
}

/* Right side - Copyright */
.footer-right {
  flex: 1;
  text-align: right;
  font-size: 14px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    text-align: center;
  }

  .footer-left,
  .footer-center,
  .footer-right {
    margin: 10px 0;
    text-align: center;
  }

  .footer-logo {
    width: 100px;
  }

  .footer-link {
    display: block;
    margin: 5px 0;
  }
}
header + * {
  display: flex;
  flex-direction: column;
  flex: 1;
}
